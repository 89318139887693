import ArrowIcon from "assets/icons/ArrowIcon";
import ShiftDateIcon from "assets/icons/ShiftDateIcon";
import ShiftTimeIcon from "assets/icons/ShiftTimeIcon";
import { labels } from "features/locale/it-it";
import { dateParser } from "features/utils/dateUtils";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import './style.scss'
import { Shift } from "features/storeManagement/services/models/ShiftsResponse";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";

export interface ShiftItemData {
	shiftItem: Shift
}


const ShiftItem: React.FC <ShiftItemData> = ({shiftItem}) => {	
	const parser = shiftItem.shiftStartTime && dateParser(shiftItem.shiftStartTime);
	const navigate = useNavigate();
	const isOperatoreEsterno = getValuesForUser().getUserRole() === roles.corrispondente.key;
	const location = useLocation();
	const customerAddressId = location.state?.customerAddressIdData.customerAddress.id

	const handleNavigate = () => {
        const shiftData = { shiftItem };
        navigate(navigatePath, { state: { shiftData, customerAddressId } });
    };
	
	const navigatePath = isOperatoreEsterno ? `/shift/${shiftItem.id}/customer/${shiftItem.shiftCustomerId}` : `/shift/${shiftItem.shiftId}`

	return (
		<Row className="d-flex justify-content-space-between align-items-center shift-box flex-nowrap cursor-pointer"
			onClick={() => handleNavigate()}>
			<Col className="col-1 mx-1"></Col>
			<Col className="col-9 shift-details ps-sm-4 ps-md-2">
				<Row className="shift-type">
					<Col className="blue-text bold-text">
						<div>{shiftItem.shiftJobDescription && shiftItem.shiftJobDescription}</div>
					</Col>
				</Row>
				<Row className="client-name">
					<Col className="blue-text bold-text">{shiftItem.shiftCustomerName && shiftItem.shiftCustomerName.toLowerCase()}</Col>
				</Row> 
				<Row className="address">
					<Col className="">{shiftItem.shiftFullAddress && shiftItem.shiftFullAddress.toLowerCase()}</Col>
				</Row>
				<Row className="address">
					<Col className="">{shiftItem.shiftVenueName && shiftItem.shiftVenueName.toLowerCase()}</Col>
				</Row>
				<Row className="d-flex flex-nowrap shift-time-container">
					<Col className="datetime-box small start-date px-0 col-6">
						<div className="info d-flex flex-column">
							<span className="label">{labels.dataInizio}</span>
							<span className='day'>{parser && parser.getDay()}</span>
						</div>
					</Col>
					<Col className="datetime-box small start-hour px-0 col-6">
						<div className="info d-flex flex-column">
							<span className="label">{labels.oraInizio}</span>
							<span className='hour'>{parser && parser.getHour().substring(0,5)}</span>
						</div>
					</Col>
				</Row>
			</Col>
			<Col className="col-2 arrow p-0 text-start">
				<ArrowIcon />
			</Col>
		</Row>
	)
}

export default ShiftItem