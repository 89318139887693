import "./style.scss";
import React, { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Container } from "react-bootstrap";
import { Shift } from "features/storeManagement/services/models/ShiftsResponse";
import ShiftDetailsTop from "components/modules/Shift/ShiftDetails/ShiftDetailsTop";
import ShiftDetailsBottom from "components/modules/Shift/ShiftDetails/ShiftDetailsBottom";
import { BoxDetailsWrapper } from "../BoxDetails/BoxDetailsWrapper";

library.add(fas);

interface BoxDetailsProps {
  shift?: Shift | null;
  isCorrispondentePage?: boolean;
}

const BoxDetailsCorrispondente: React.FC<BoxDetailsProps> = ({ shift }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <BoxDetailsWrapper>
        <Container fluid className="mt-3 px-1 z-index-99">
          {shift && (
            <ShiftDetailsTop
              shift={shift}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          )}
        </Container>
        <div
          className={`box-details-accordion p-1 mx-auto ${
            isOpen ? "open" : ""
          } ${shift ? "standard" : "small"} ${shift ? "" : "absolute"}`}
        >
          <Container
            fluid
            className="box-modules-container base-container fade-content mt-2 p-0"
          >
            {shift && <ShiftDetailsBottom shift={shift} />}
          </Container>
        </div>
      </BoxDetailsWrapper>
    </>
  );
};

export default BoxDetailsCorrispondente;
