import "./style.scss";
import { useContext, useEffect, FC } from "react";
import { Col, Nav, Navbar, Row } from "react-bootstrap";
import { navbarBreakPoint } from "../../../config";
import AppContext from "theme/context/Context";
import Flex from "theme/components/common/Flex";
import ToggleButton from "./ToggleButton";
import ProfileItems from "../common/ProfileItems";
import { getLabel, labels } from "features/locale/it-it";
import { useSelector } from "react-redux";
import { RootState } from "features/storeManagement/store";
import Logout from "assets/icons/Logout";
import { Link } from "react-router-dom";
import { useAttemptLogoutMutation } from "features/storeManagement/services/loginService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import ModulesList from "components/modules/ModulesList";
import ProfileIcon from "assets/icons/ProfileIcon";
import { Shift } from "features/storeManagement/services/models/ShiftsResponse";
import ModulesListReport from "components/modules/ModuleListReport/ModuleListReport";
import { Checklist, DocumentToDownload } from "features/storeManagement/services/models/ChecklistResponse";
import { PBIReportResponse } from "features/storeManagement/services/models/ReportResponse";
import { responsabileResponse } from "features/storeManagement/services/models/ResponsabileResponse";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { roles } from "features/storeManagement/services/constants/roles";
import { CustomerModulesResponse } from "features/storeManagement/services/models/CustomerResponse";

interface NavbarVerticalProps {
	shiftData?: Shift;
	audits?: Checklist[];
	report?: PBIReportResponse[];
	responsabileData?: responsabileResponse;
	isLoadingData?: boolean;
	customerData?:CustomerModulesResponse;
	documents?: DocumentToDownload[];
}

const NavbarVertical: FC<NavbarVerticalProps> = function NavbarVertical({
	shiftData,
	audits,
	report,
	responsabileData,
	isLoadingData,
	customerData,
	documents
}) {
	const isResponsabile =
		getValuesForUser().getUserRole() === roles.responabile.key;
	const isCustomer =
		getValuesForUser().getUserRole() === roles.cliente.key;
	const {
		config: {
			navbarStyle,
			isNavbarVerticalCollapsed,
			showBurgerMenu,
			showMenu,
			isMobile,
		},
		setConfig,
	} = useContext(AppContext);

	const openMenu = showMenu || isMobile;
	const HTMLClassList = document.getElementsByTagName("html")[0].classList;

	useEffect(() => {
		if (isNavbarVerticalCollapsed) {
			HTMLClassList.add("navbar-vertical-collapsed");
		} else {
			HTMLClassList.remove("navbar-vertical-collapsed");
		}
		return () => {
			HTMLClassList.remove("navbar-vertical-collapsed-hover");
		};
	}, [isNavbarVerticalCollapsed, HTMLClassList]);

	let time: NodeJS.Timeout;
	const handleMouseEnter = (): void => {
		if (isNavbarVerticalCollapsed) {
			time = setTimeout(() => {
				HTMLClassList.add("navbar-vertical-collapsed-hover");
			}, 100);
		}
	};
	const handleMouseLeave = (): void => {
		clearTimeout(time);
		HTMLClassList.remove("navbar-vertical-collapsed-hover");
	};

	const handleNavItemClick = (): void => {
		if (showBurgerMenu) {
			setConfig("showBurgerMenu", !showBurgerMenu);
		}
	};

	const [logoutCall] = useAttemptLogoutMutation({
		fixedCacheKey: fixedCacheKeys.logout,
	});

	const handleLogout = () => {
		logoutCall(localStorage.token || sessionStorage.token);
	};

	const firstName = useSelector(
		(store: RootState) => store.loggedInSlice.firstName
	);
	const lastName = useSelector(
		(store: RootState) => store.loggedInSlice.lastName
	);

	return (
		<Navbar
			expand={openMenu ? navbarBreakPoint : false}
			className="navbar-vertical sicurforce m-0"
			variant="light"
		>
			<Flex alignItems="center" className="burger-container ps-1">
				<ToggleButton />
			</Flex>

			<Navbar.Collapse
				in={showBurgerMenu}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={{}}
			>
				<div className="navbar-vertical-content p-0 px-2 scrollbar sidebar-menu-end">
					{isMobile && (
						<Row className="welcome-message blue-text text-capitalize sidebar-menu-row m-0 h-50">
							<Col className="col-11 p-0 m-0">
								<ProfileIcon />
								<span>
									{getLabel("welcome", [
										{ i: "firstname", v: firstName },
										{ i: "lastname", v: lastName },
									]).toLowerCase()}
								</span>
							</Col>
							<Col className="col-1 p-0 m-0 ms-auto">
								<Nav.Link
									className="text-end"
									as={Link}
									to="/"
									onClick={handleLogout}
								>
									<div className="nav-logout">
										<Logout />
										<p className="logout-text">Esci</p>
									</div>
								</Nav.Link>
							</Col>
						</Row>
					)}
					{showMenu && (shiftData || audits || documents || report) && !isResponsabile && !isCustomer && (
						<Nav className="flex-column nav" as="ul">
							<ModulesList
								asMenu
								separator={isMobile}
								shiftId={shiftData!.shiftId}
								checklists={shiftData!.checklists}
								audits={audits}
								patrols={shiftData!.patrols}
								incidents={shiftData!.incidents}
								timbrature={responsabileData?.isMonitorEnabled}
								journal={shiftData!.journal}
								parcel={shiftData!.parcel}
								keys={shiftData!.keyManagement}
								guestConfiguration={shiftData?.guestConfiguration}
								areaDocumenti={shiftData?.documents}
							/>

							{report && !!report.length && (
								<ModulesListReport asMenu report={report} />
							)}
						</Nav>
					)}
					{showMenu && responsabileData && isResponsabile && (
						<Nav className="flex-column nav" as="ul">
							<ModulesList
								asMenu
								separator={isMobile}
								shiftId={""}
								checklists={responsabileData?.checklists}
								incidents={responsabileData?.incidents}
								journal={responsabileData?.journal}
								parcel={responsabileData?.parcel}
								keys={responsabileData?.keyManagement}
								guestConfiguration={responsabileData?.guestConfiguration}
								areaDocumenti={responsabileData?.documents}
								timbrature={responsabileData?.isMonitorEnabled} 
							/>

							{report && !!report.length && (
								<ModulesListReport asMenu report={responsabileData?.reports} />
							)}
						</Nav>
					)}
					{showMenu && customerData && isCustomer && (
						<Nav className="flex-column nav" as="ul">
							<ModulesList
								asMenu
								separator={isMobile}
								shiftId={""}
								checklists={customerData?.checklists}
								incidents={customerData?.incidents}
								journal={customerData?.journal}
								parcel={customerData?.parcel}
								keys={customerData?.keyManagement}
								areaDocumenti={customerData?.documents}
							/>

							{report && !!report.length && (
								<ModulesListReport asMenu report={report} />
							)}
						</Nav>
					)}
					{isMobile && (
						<Nav className="flex-column nav" as="ul">
							{isMobile && <ProfileItems />}
						</Nav>
					)}
				</div>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default NavbarVertical;
