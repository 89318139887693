import { FC, useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys"
import { labels } from 'features/locale/it-it';
import './style.scss';
import { useChangeDocumentTitle } from 'features/hooks/useChangeDocumentTitle';
import { useNavigate } from 'react-router-dom';
import { useGetCustomerMutation } from 'features/storeManagement/services/customerService';
import { Form, Button } from 'react-bootstrap'
import { stringUtils } from 'features/utils/stringUtils';
import { useGetCurrentShiftMutation, usePostCurrentShiftMutation } from 'features/storeManagement/services/shiftsService';
import EmptyPageNote from 'components/content/EmptyPageNote/EmptyPageNote';
import AlertModal from 'components/content/modals/AlertModal/AlertModal';
import ErrorBoundary from 'features/ErrorBoundary'
import Suspense from 'features/Suspense'
import { selectOptionObject } from 'features/storeManagement/services/models/ResponsabileResponse';
import { CustomerAddress, CustomerResponse, CustomerTipology } from 'features/storeManagement/services/models/CustomerResponse';
import Select from 'react-select'
import { getValuesForUser } from 'features/utils/userRoleUtils';
import { roles } from 'features/storeManagement/services/constants/roles';


const CorrispondentePage: FC = function CorrispondentePage() {
	useChangeDocumentTitle(labels.pageTile_CorrispondentePage);
	
	const navigate = useNavigate()
	const [showEmptyNote, setShowEmptyNote] = useState<boolean>(false);
	const [showAlertModal, setShowAlertModal] = useState<boolean>(false)
	const [customerSelection, setCustomerSelection] = useState<number>(-1);
	const [customerAddressSelection, setCustomerAddressSelection] = useState<selectOptionObject>({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""});
	const [customerTipologySelection, setCustomerTipologySelection] = useState<selectOptionObject>({id: -1, value: labels.tipologyServiceSelectDefault, label: labels.tipologyServiceSelectDefault, serviceAddress: "", tipologyService: ""})
	const [isCustomerAddressSelectionEnabled, setIsCustomerAddressSelectionEnabled] = useState<boolean>(false);
	const [isCustomerTipologySelectionEnabled, setIsCustomerTipologySelectionEnabled] = useState<boolean>(false);
	const [optionsAddress, setOptionsAddress] = useState<selectOptionObject[]>([{id: -1, value: "", label: labels.customerSelectDefault, serviceAddress: ""}]);
	const [optionsTipology, setOptionsTipology] = useState<selectOptionObject[]>([{id: -1, value: "", label: labels.tipologyServiceSelectDefault, serviceAddress: "", tipologyService: ""}]);

	const isOperatoreEsterno = getValuesForUser().getUserRole() === roles.corrispondente.key;

	const [postCurrentShift, {
		data: postCurrentShiftData,
		isLoading: postCurrentShiftIsLoading,
		error: postCurrentShiftError
	}] = usePostCurrentShiftMutation()

	const [getCustomers, {
		data: customersData,
		isLoading: customersIsLoading,
		isError: isCustomersError,
		error: customerError
	}] = useGetCustomerMutation({ fixedCacheKey: fixedCacheKeys.customersData })

	const [, {
		data: currentShiftData,
		isLoading: currentShiftIsLoading,
		error: currentShiftError
	}] = useGetCurrentShiftMutation({ fixedCacheKey: fixedCacheKeys.currentShiftData })

	// Create a ref to prevent multiple calls to getCustomers on re-renders
    const customersFetched = useRef(false);

    // Use a single useEffect to handle the logic for both shift data and customer data fetching
    useEffect(() => {
        // If the user is an external operator, and there's no currentshift or customer data, fetch customers
        if (isOperatoreEsterno && !currentShiftData && !customersFetched.current) {
            if (!customersData && !customersIsLoading && !isCustomersError) {
                getCustomers();
                customersFetched.current = true; // Prevents multiple calls
            }
        }

        // If there's currentShiftData, handle redirection
        if (currentShiftData && !currentShiftIsLoading) {
            const shiftIdCurrentShift = currentShiftData.shiftId;
            const customerCurrentShift = currentShiftData.shiftCustomerId;
			navigate(`shift/${shiftIdCurrentShift}/customer/${customerCurrentShift}`);
        } 
		
    }, [isOperatoreEsterno, currentShiftData, currentShiftIsLoading, currentShiftError, customersData, customersIsLoading, isCustomersError]);

  

	useEffect(() => {
		setShowEmptyNote(!!(customersData && customersData.length === 0));
	}, [customersData])


	useEffect(() => {
		if (postCurrentShiftError) {
			setShowAlertModal(true)
		}
	}, [postCurrentShiftError])


	const handleCustomerSelectionChange = (event: any) => {
		setCustomerSelection(event.id);
		setIsCustomerAddressSelectionEnabled(event.id != -1);
		setCustomerAddressSelection({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""})
	};

	const handleCustomerAddressSelectionChange = (event: any) => {
		setIsCustomerTipologySelectionEnabled(event.id != -1)
		setCustomerAddressSelection(event)
	};

	const handleCustomerTipologySelectionChange = (event: any) => {
		setCustomerTipologySelection(event)
	};

	const customerId = customersData && customersData[customerSelection]?.addresses[customerAddressSelection.id]?.id
	const customerAddress = customersData && customersData[customerSelection]?.addresses[customerAddressSelection.id]
	const customerServiceType = customerAddress && customerAddress?.shiftServices?.[customerTipologySelection.id]?.shiftServiceType

	const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
		const navigatePath = `/customer/${customerAddress?.idCustomer}/shiftList`;
		const customerAddressIdData = { customerAddress };
		navigate(navigatePath, { state: { customerAddressIdData, customerServiceType } });
		
	}


	const options:selectOptionObject[] = [{id: -1, value: labels.customerSelectDefault, label: labels.customerSelectDefault, serviceAddress: ""}]

	customersData?.map((singleData: CustomerResponse, i: number) => {

		options.push({id: i, value: singleData.customerName, label: stringUtils(singleData.customerName).capitalize(), serviceAddress: singleData.serviceAddress})

	})

	useEffect(()=> {
		setCustomerAddressSelection({id: -1, value: labels.addressSelectDefault, label: labels.addressSelectDefault, serviceAddress: ""});
		setCustomerTipologySelection({id: -1, value: labels.tipologyServiceSelectDefault, label: labels.tipologyServiceSelectDefault, serviceAddress: "", tipologyService: ""});
		let addressesOptions: any[] = [];
		let tipologyOptions: any[] = [];
		customersData && customersData[customerSelection]?.addresses.map((singleData: CustomerAddress, i: number) => {
			addressesOptions.push({id: i, value: singleData.fullAddress, label: stringUtils(singleData.fullAddress).capitalize(), serviceAddress: singleData.serviceAddress || ""});
		})
		
		customersData && customersData[customerSelection]?.addresses.map((singleData: CustomerAddress, i: number) => {
			singleData?.shiftServices?.map((el: CustomerTipology, index: number) => {
				tipologyOptions.push({id: index, value: el.shiftService, label: stringUtils(el.shiftService).capitalize(), tipologyService: el.shiftService});
			})
			
		})
		
		setOptionsTipology(tipologyOptions);
		setOptionsAddress(addressesOptions);
	}, [customerSelection])



	return (
		<ErrorBoundary errorMessage={customerError || postCurrentShiftError}>
			<Suspense isLoading={currentShiftIsLoading || customersIsLoading || postCurrentShiftIsLoading || !!postCurrentShiftData} fullPage>
				<Container fluid className='p-1'>
					{showEmptyNote &&
						<EmptyPageNote note={labels.noCurrentShiftAndCustomers} isLoading={currentShiftIsLoading} />
					}
					{customersData && !!customersData.length && !showEmptyNote &&
						<div className="select-element">
							<div className='select-element-content'>
								<Form id="form-section" onSubmit={handleSubmit}>
									<Form.Label
										htmlFor='customer-selection'
										className='customer-selection-label mb-0'
									>
										{labels.customer}
									</Form.Label>


									<Select
										placeholder={labels.customerSelectDefault}
										aria-label={labels.customer}
										className='mb-3'
										onChange={handleCustomerSelectionChange}
										options={options}
									/>

									<Form.Label
										htmlFor='customer-address-selection'
										className='customer-address-selection-label mb-0'
									>
										{labels.customerAddress}
									</Form.Label>

									<Select 
										placeholder={labels.addressSelectDefault}
										aria-label={labels.addressSelectDefault}
										className={`select-field-question mb-3 w-100`}
										onChange={handleCustomerAddressSelectionChange}
										isDisabled={!isCustomerAddressSelectionEnabled}
										value={customerAddressSelection}
										options={optionsAddress}
									/>

									<Form.Label
										htmlFor='customer-address-selection'
										className='customer-address-selection-label mb-0'
									>
										{labels.tipologyService}
									</Form.Label>

									<Select 
										placeholder={labels.tipologyServiceSelectDefault}
										aria-label={labels.tipologyServiceSelectDefault}
										className={`select-field-question mb-4 w-100`}
										onChange={handleCustomerTipologySelectionChange}
										isDisabled={!isCustomerTipologySelectionEnabled}
										value={customerTipologySelection}
										options={optionsTipology}
									/>

									<div className='button-content'>
										<Button
											type="submit"
											id="submit"
											form='form-section'
											className='submit-button'
											variant="green"
											disabled={customerAddressSelection.id < 0 || customerTipologySelection.id < 0}
										>
											{labels.homeSubmit}
										</Button>
									</div>
								</Form>
							</div>
						</div>
					}
					<AlertModal
						headerMessage={labels.watchOut}
						bodyMessage={labels.tryLaterPlease}
						okButtonText={labels.ok}
						showAlertModal={showAlertModal}
						setShowAlertModal={setShowAlertModal}
					/>
				</Container>
			</Suspense>
		</ErrorBoundary>
	);
};

export default CorrispondentePage;