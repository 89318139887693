import React, { FC } from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { labels } from "features/locale/it-it";

const Error400: FC = function Error400() {
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">
          {labels.upsThereWasAProblem}
        </div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {labels.upsThereWasAProblemMessage}
        </p>
        <hr />
        <p>
          {labels.pleaseRetry} {/* {labels.ifThereIsAProblem} */}
          {/* <a href='/' className='ms-1'>
						{labels.contactUs}
					</a> */}
          {/* . */}
        </p>
        <Link className="btn btn-primary btn-sm mt-3" to="/">
          <FontAwesomeIcon icon={faHome as IconProp} className="me-2" />
          {labels.takeMeToHomepage}
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Error400;
