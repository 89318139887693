import "./style.scss";
import React, { FC, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { AppContext } from "theme/context/Context";
import classNames from "classnames";
import Footer from "components/footer";
import NavbarTop from "components/navbar/top/NavbarTop";
import NavbarVertical from "components/navbar/vertical/NavbarVertical";
import { useMediaQuery } from "features/hooks/useMediaQuery";
import {
	useGetCurrentShiftMutation,
	useGetOneShiftMutation,
} from "features/storeManagement/services/shiftsService";
import { getValuesForUser } from "features/utils/userRoleUtils";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { Shift } from "features/storeManagement/services/models/ShiftsResponse";
import { roles } from "features/storeManagement/services/constants/roles";
import { useGetReportListMutation } from "features/storeManagement/services/reportService";
import { useGetAllAuditsMutation } from "features/storeManagement/services/checklistService";
import { useGetModuliResponsabileMutation } from "features/storeManagement/services/responsabileService";
import { useGetModuliCustomerMutation } from "features/storeManagement/services/customerService";

const MainLayout: FC = function MainLayout() {
	const { hash, pathname } = useLocation();
	const isKanban = pathname.includes("kanban");

	const {
		config: { navbarPosition, showMenu, isMobile },
		setConfig,
	} = useContext(AppContext);

	const mobileTest = useMediaQuery();
	const isSidebarMenuPage =
		(!!pathname.includes("/checklist") ||
			!!pathname.includes("/patrol") ||
			!!pathname.includes("/journal") ||
			!!pathname.includes("/incident") ||
			!!pathname.includes("/parcel") ||
			!!pathname.includes("/keys") ||
			!!pathname.includes("/audit") ||
			!!pathname.includes("/report") ||
			!!pathname.includes("/turni") ||
			!!pathname.includes("/turno") || 
			!!pathname.includes("/controlloAccessi") ||
			!!pathname.includes("/documents") ||
			!!pathname.includes("/timbrature")) &&
		!pathname.includes("/powerBi");

	const isOperatoreEsterno =
		getValuesForUser().getUserRole() === roles.corrispondente.key;
	const isCliente = getValuesForUser().getUserRole() === roles.cliente.key;
	const isCoordinatore =
		getValuesForUser().getUserRole() === roles.coordinatore.key;
	const isResponsabile =
		getValuesForUser().getUserRole() === roles.responabile.key;
	const isOperatoreInterno =
		getValuesForUser().getUserRole() === roles.operatore.key;

	const {
		shiftId,
		checklistId,
		patrolId,
		auditId,
		customer,
		serviceAddress
	} = useParams();
	const [shiftMenu, setShiftMenu] = useState({});
	const customerContext = customer !== undefined && serviceAddress !== undefined;

	useEffect(() => {
		setTimeout(() => {
			if (hash) {
				const id = hash.replace("#", "");
				const element = document.getElementById(id);
				if (element) {
					element.scrollIntoView({ block: "start", behavior: "smooth" });
				}
			}
		}, 0);
	}, []);

	useEffect(() => {
		if (mobileTest !== isMobile) {
			setConfig("isMobile", mobileTest);
		}

		if (isSidebarMenuPage !== showMenu) {
			setConfig("showMenu", isSidebarMenuPage);
		}
	}, [mobileTest, isSidebarMenuPage]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const [getOneShift, { data: shiftData, isLoading: shiftIsLoading }] =
		useGetOneShiftMutation({ fixedCacheKey: fixedCacheKeys.shiftData });

	const [
		getCurrentShift,
		{
			data: currentShiftData,
			isLoading: currentShiftIsLoading,
			error: currentShiftError,
		},
	] = useGetCurrentShiftMutation({
		fixedCacheKey: fixedCacheKeys.currentShiftData,
	});

	const [
		getModuliResponsabile,
		{
			data: moduliResponsabile,
			isLoading: moduliResponsabileIsLoading,
			isError: moduliResponsabileError,
			error: moduliResponsabileErrorBoundary,
		},
	] = useGetModuliResponsabileMutation({
		fixedCacheKey: fixedCacheKeys.moduliResponsabile,
	});

	
	const [
		getModuliCustomer,
		{
			data: moduliCustomer,
			isLoading: moduliCustomerIsLoading,
			isError: moduliCustomerError,
			error: moduliCustomerErrorBoundary,
		},
	] = useGetModuliCustomerMutation({
		fixedCacheKey: fixedCacheKeys.moduliCustomer,
	});

	useEffect(() => {
		if (isOperatoreEsterno) {
			if (!currentShiftData) {
				getCurrentShift();
			}
		} else if (shiftId) {
			getOneShift(shiftId);
		} else if (isCoordinatore) {
			/* Any value could be zero */
			const params = customerContext
				? {
					idCustomer: customer,
					serviceAddress: serviceAddress
				  }
				: {};
			getAllAudits(params);
		} else if (isResponsabile) {
			/* Any value could be zero */
			const params = customerContext
				? {
					idCustomer: customer,
					serviceAddress: serviceAddress
				  }
				: {};
			if (customer && serviceAddress) {
				getAllAudits(params);
				getModuliResponsabile({
					idCustomer: customer,
					serviceAddress: serviceAddress
				});
			}
		}else if (isCliente){
			getModuliCustomer()
		}
	}, [shiftId, checklistId, patrolId, customer, serviceAddress]);

	useEffect(() => {
		if (!!shiftData) {
			setShiftMenu(shiftData);
		}
	}, [shiftData]);

	useEffect(() => {
		if (!!currentShiftData) {
			setShiftMenu(currentShiftData);
		}
	}, [currentShiftData]);

	const [getAllAudits, { data: allAudits, isLoading: allAuditsIsLoading }] =
		useGetAllAuditsMutation({ fixedCacheKey: fixedCacheKeys.allAuditsData });

	const [
		getReportList,
		{ data: reportData, isError: reportIsError, isLoading: reportIsLoading },
	] = useGetReportListMutation({ fixedCacheKey: fixedCacheKeys.reportsData });

	useEffect(() => {
		if (isCliente || isCoordinatore || isResponsabile || isOperatoreInterno) {
			getReportList();
		}
	}, []);

	const audits = ((internal?: boolean) => {
		if (allAudits && allAudits.items.length) {
			return allAudits?.items.filter(
				(audit) => (internal === undefined || internal === audit.internalAudit)
			);
		}
		return [];
	})();

	return (
		<div className="container-fluid main-layout-container m-0 p-0">
			{(showMenu || isMobile) &&
				(shiftMenu || audits) &&
				(navbarPosition === "vertical" || navbarPosition === "combo") && (
				<NavbarVertical
					shiftData={shiftMenu as Shift}
					audits={audits}
					report={reportData}
					responsabileData={moduliResponsabile}
					customerData = {moduliCustomer}
					isLoadingData={shiftIsLoading || allAuditsIsLoading}
				/>
			)}
			{/* <ProductProvider> */}
			<div className={classNames("content", { "pb-0": isKanban })}>
				<NavbarTop />
				{/* ------ Main Routes ------*/}
				<Outlet />
				{!isKanban && <Footer />}
			</div>
			{/* </ProductProvider> */}
		</div>
	);
};

export default MainLayout;
