import React, { SetStateAction, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "./style.scss";
import PenToSquareIcon from "assets/icons/PenToSquareIcon";
import PlusIcon from "assets/icons/PlusIcon";
import TimbratureModal from "./TimbratureModal";
import TrashCanIcon from "assets/icons/TrashCanIcon";
import { Fabbisogno } from "features/storeManagement/services/models/MonitorsResponse";
import { useDeleteMonitorsStampingMutation } from "features/storeManagement/services/monitorsService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import ConfirmModal from "components/content/ConfirmModal";
import { labels } from "features/locale/it-it";

interface TimbratureTableProps {
  data: Fabbisogno[];
  setHasUpdatedTimbrature: React.Dispatch<SetStateAction<boolean>>;
}

const TimbratureTable: React.FC<TimbratureTableProps> = ({
  data,
  setHasUpdatedTimbrature,
}) => {
  const [
    deleteMonitorsStamping,
    {
      data: deleteMonitorsStampingResponse,
      isLoading: deleteMonitorsStampingIsLoading,
      error: deleteMonitorsStampingError,
      isError: deleteMonitorsStampingIsError,
      isSuccess: deleteMonitorsStampingIsSuccess,
    },
  ] = useDeleteMonitorsStampingMutation({
    fixedCacheKey: fixedCacheKeys.deleteMonitorsStampingResponse,
  });

  useEffect(() => {
    if (deleteMonitorsStampingIsSuccess) {
      setHasUpdatedTimbrature(true);
    } else if (deleteMonitorsStampingIsError) {
      setHasUpdatedTimbrature(false);
    }
    setShowConfirmModal(false);
  }, [deleteMonitorsStampingIsError, deleteMonitorsStampingIsSuccess]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedDataModal, setSelectedDataModal] = useState<Fabbisogno | null>(
    null
  );
  const [isEditEnabled, setIsEditEnable] = useState(false);
  const [operatorIndex, setOperatorIndex] = useState<number>(0);
  const [element, setElement] = useState<{} | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const handleModalAddOpen = (data: Fabbisogno[], row: Fabbisogno) => {
    const operatorList =
      data.find((item) => item.id === row.id)?.operators || [];

    const updatedRow: Fabbisogno = {
      ...row,
      operators: operatorList,
    };
    setSelectedDataModal(updatedRow);

    setTimeout(() => {
      setShowModal(true);
    }, 0);
  };

  const handleModalEditOpen = (row: Fabbisogno, operatorIndex: number) => {
    const updatedRow: Fabbisogno = {
      ...row,
      operators: [row.operators[operatorIndex]],
    };
    setSelectedDataModal(updatedRow);

    setTimeout(() => {
      setShowModal(true);
    }, 0);
  };

  const handleDeleteOperator = (element: Fabbisogno, operatorIndex: number) => {
    if (element) {
      deleteMonitorsStamping({
        externalStampingId: element.operators[operatorIndex].id,
        body: {
          shiftId: element.id,
        },
      });
    }
  };

  return (
    <div className="mt-3">
      <Table borderless responsive bgcolor="white">
        <thead className="table-header">
          <tr>
            <th className="responsive-text">{labels.tableMonitorsContract}</th>
            <th className="responsive-text">{labels.tableMonitorsPosition}</th>
            <th className="responsive-text">{labels.tableMonitorsService}</th>
            <th className="responsive-text">
              {labels.tableMonitorsDestination}
            </th>
            <th className="responsive-text">
              {labels.tableMonitorsRequirement}
            </th>
            <th className="responsive-text">{labels.tableMonitorsCoverage}</th>
            <th className="responsive-text">{labels.tableMonitorsStatus}</th>
            <th className="responsive-text">{labels.tableMonitorsOperator}</th>
            <th className="responsive-text">
              {labels.tableMonitorsStartStamping}
            </th>
            <th className="responsive-text">
              {labels.tableMonitorsEndStamping}
            </th>
            <th className="responsive-text">
              {labels.tableMonitorsManagement}
            </th>
            <th className="responsive-text">{labels.tableMonitorsAddDelete}</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((element, index) => (
              <React.Fragment key={index}>
                {/* Check if operators exist */}
                {element.operators.length > 0 ? (
                  element.operators.map((el, i) => (
                    <tr key={i} className="table-row">
                      {i === 0 && (
                        <>
                          <td
                            rowSpan={element.operators.length}
                            className="responsive-text"
                          >
                            {element.contract}
                          </td>
                          <td
                            rowSpan={element.operators.length}
                            className="responsive-text"
                          >
                            {element.position}
                          </td>
                          <td
                            rowSpan={element.operators.length}
                            className="responsive-text"
                          >
                            {element.service}
                          </td>
                          <td
                            rowSpan={element.operators.length}
                            className="responsive-text"
                          >
                            {element.destination}
                          </td>
                          <td
                            rowSpan={element.operators.length}
                            className="responsive-text"
                          >
                            {element.requirement}
                          </td>
                          <td
                            rowSpan={element.operators.length}
                            className="responsive-text"
                          >
                            {element.coverage}
                          </td>
                          <td
                            rowSpan={element.operators.length}
                            className="responsive-text"
                          >
                            <div className="d-flex-table justify-content-center">
                              <div
                                className={`status-circle ${element.status}`}
                              ></div>
                            </div>
                          </td>
                        </>
                      )}
                      {/* Operator data */}
                      {el.operatorCid !== null ? (
                        <>
                          <td className="responsive-text item_element_single_operator_name">{el.name}</td>
                          <td className="responsive-text">
                            {el.isManualCheckIn
                              ? el.manualCheckInDate + "(M)"
                              : el.checkInDate}
                          </td>
                          <td className="responsive-text">
                            {el.isManualCheckOut
                              ? el.manualCheckOutDate + "(M)"
                              : el.checkOutDate}
                          </td>

                          <td className="responsive-text">
                            <div className="d-flex justify-content-center">
                              <PenToSquareIcon
                                type="button"
                                onClick={() => {
                                  setIsEditEnable(true);
                                  handleModalEditOpen(element, i);
                                }}
                              />
                            </div>
                          </td>
                          <td className="responsive-text">
                            <div className="d-flex justify-content-center">
                              <PlusIcon
                                type="button"
                                color={"#00A898"}
                                onClick={() => {
                                  setIsEditEnable(false);
                                  setOperatorIndex(i);
                                  handleModalAddOpen(data, element);
                                }}
                              />
                              {element.operators.length > 1 && (
                                <TrashCanIcon
                                  type="button"
                                  onClick={() => {
                                    setShowConfirmModal(true);
                                    setElement(element);
                                    setOperatorIndex(i);
                                  }}
                                />
                              )}
                            </div>
                          </td>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  ))
                ) : (
                  <tr className="table-row">
                    <td className="responsive-text">{element.contract}</td>
                    <td className="responsive-text">{element.position}</td>
                    <td className="responsive-text">{element.service}</td>
                    <td className="responsive-text">{element.destination}</td>
                    <td className="responsive-text">{element.requirement}</td>
                    <td className="responsive-text">{element.coverage}</td>
                    <td className="responsive-text">
                      <div className="d-flex-table justify-content-center">
                        <div className="d-flex-table justify-content-center">
                          <div
                            className={`status-circle ${element.status}`}
                          ></div>
                        </div>
                      </div>
                    </td>
                    <td className="responsive-text"></td>
                    <td className="responsive-text"></td>
                    <td className="responsive-text"></td>
                    <td className="responsive-text"></td>
                    <td className="responsive-text">
                      <div className="d-flex justify-content-center">
                        <PlusIcon
                          color={"#00A898"}
                          onClick={() => {
                            setIsEditEnable(false);
                            setOperatorIndex(0);
                            handleModalAddOpen(data, element);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
        </tbody>
      </Table>
      <ConfirmModal
        headerMessage={labels.warning}
        bodyMessage={labels.modalSureToDeleteStamping}
        confirmButtonText={labels.textButtonDeleteStamping}
        showConfirmModal={showConfirmModal}
        setShowConfirmModal={setShowConfirmModal}
        isLoading={deleteMonitorsStampingIsLoading}
        onConfirm={() => {
          handleDeleteOperator(element as Fabbisogno, operatorIndex);
        }}
      />
      <TimbratureModal
        showModal={showModal}
        setShowModal={setShowModal}
        onHide={false}
        dataModal={selectedDataModal}
        isEditEnabled={isEditEnabled}
        operatorIndex={operatorIndex}
        setHasUpdatedTimbrature={setHasUpdatedTimbrature}
      />
    </div>
  );
};

export default TimbratureTable;
