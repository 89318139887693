import { settingsType } from "features/types";

export const appName: string = 'Sicurforce';
export const version: string = '1.9.6';
export const navbarBreakPoint: 'xl' = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint: 'lg' = 'lg';
export const settings: settingsType = {
	isFluid: false,
	isRTL: false,
	isDark: false,
	isMobile: false,
	navbarPosition: 'vertical',
	showBurgerMenu: false, // controls showing vertical nav on mobile
	currency: '€',
	isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
	navbarStyle: 'transparent',
	showSettingPanel: false,
	navbarCollapsed: false,
	showMenu: false,
};
const appSettings = {
	version,
	navbarBreakPoint,
	topNavbarBreakpoint,
	settings,
};
export default appSettings;
