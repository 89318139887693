import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./queryFuncs/baseQueryWithReauth";
import { headersCommon } from "./constants/headers";
import {
  Monitors,
  MonitorsCausals,
  MonitorsCustomer,
  MonitorsCustomerService,
  MonitorsEditRequest,
  MonitorsEditOrAddRequestBody,
  Operator,
  OperatorStampings,
  MonitorsDeleteStampingsRequest,
} from "./models/MonitorsResponse";
import { MonitorsRequest } from "./models/MonitorsRequest";

export const monitorsService = createApi({
  reducerPath: "monitorsService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getMonitors: builder.mutation<Monitors, MonitorsRequest>({
      query: ({
        startDate,
        endDate,
        pagination,
        customerId,
        serviceAddress,
        operatorCid,
        page,
        status,
        isManual,
      }) => ({
        url: `/fe/Monitors?startDate=${startDate ? startDate : ""}&endDate=${
          endDate ? endDate : ""
        }&pagination=${pagination}&customerId=${customerId}&serviceAddress=${
          serviceAddress ? serviceAddress : ""
        }&operatorCid=${operatorCid ? operatorCid : ""}&page=${page}&status=${
          status ? status : ""
        }&isManual=${isManual === undefined ? "" : isManual}`,
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
      }),
    }),
    getMonitorsDownload: builder.mutation<any, MonitorsRequest>({
      query: ({
        startDate,
        endDate,
        pagination,
        customerId,
        serviceAddress,
        operatorCid,
        page,
        status,
        isManual,
      }) => ({
        url: `/fe/Monitors/Download?startDate=${
          startDate ? startDate : ""
        }&endDate=${
          endDate ? endDate : ""
        }&pagination=${pagination}&customerId=${customerId}&serviceAddress=${
          serviceAddress ? serviceAddress : ""
        }&operatorCid=${operatorCid ? operatorCid : ""}&page=${page}&status=${
          status ? status : ""
        }&isManual=${isManual === undefined ? "" : isManual}`,
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
        responseHandler: (response) => response.blob(),
      }),
    }),
    getMonitorsCustomers: builder.mutation<MonitorsCustomer[], void>({
      query: () => ({
        url: `/fe/Monitors/Customers`,
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
      }),
    }),
    getMonitorsCustomersService: builder.mutation<
      MonitorsCustomerService[],
      { customerId: number | string }
    >({
      query: ({ customerId }) => ({
        url: `/fe/Monitors/Customers/${customerId}`,
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
      }),
    }),
    getMonitorsOperators: builder.mutation<
      Operator[],
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) => ({
        url: `/fe/Monitors/Operators?startDate=${
          startDate ? startDate : ""
        }&endDate=${endDate ? endDate : ""}`,
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
      }),
    }),
    getMonitorsOperatorsStampings: builder.mutation<
      OperatorStampings[],
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/fe/Monitors/Operators/Stampings?id=${id}`,
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
      }),
    }),
    getMonitorsCausals: builder.mutation<MonitorsCausals[], void>({
      query: () => ({
        url: `/fe/Monitors/Causals`,
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
      }),
    }),
    putMonitorsEdit: builder.mutation<
      { errors: []; succeeded: boolean },
      MonitorsEditRequest
    >({
      query: ({ externalStampingId, body }) => ({
        url: `/fe/Monitors/${externalStampingId}`,
        method: "PUT",
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
        body: body,
      }),
    }),
    postMonitorsAdd: builder.mutation<
      { errors: []; succeeded: boolean },
      MonitorsEditOrAddRequestBody
    >({
      query: (body) => ({
        url: `/fe/Monitors`,
        method: "POST",
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
        body: body,
      }),
    }),
    deleteMonitorsStamping: builder.mutation<
      { errors: []; succeeded: boolean },
      MonitorsDeleteStampingsRequest
    >({
      query: ({ body, externalStampingId }) => ({
        url: `/fe/Monitors/${externalStampingId}`,
        method: "DELETE",
        headers: {
          ...headersCommon,
          Authorization: `Bearer ${localStorage.token || sessionStorage.token}`,
          deviceId:
            sessionStorage.getItem("deviceId") ||
            localStorage.getItem("deviceId") ||
            "",
        },
        body: body,
      }),
    }),
  }),
});

export const {
  useGetMonitorsMutation,
  useGetMonitorsOperatorsMutation,
  useGetMonitorsCustomersMutation,
  useGetMonitorsCausalsMutation,
  usePutMonitorsEditMutation,
  usePostMonitorsAddMutation,
  useGetMonitorsOperatorsStampingsMutation,
  useGetMonitorsCustomersServiceMutation,
  useDeleteMonitorsStampingMutation,
  useGetMonitorsDownloadMutation,
} = monitorsService;
