import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import TimbratureTable from "./TimbratureTable";
import Datepicker from "components/content/FormComponents/Datepicker";
import Select from "components/content/FormComponents/Select";
import {
  datePickerPropsTimbrature,
  selectPropsTimbrature,
} from "./Utils/utilsTimbratureFiltri";
import { ChecklistFormStateType } from "features/storeManagement/services/models/ChecklistResponse";
import Suspense from "features/Suspense";
import "./style.scss";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import {
  useGetMonitorsCustomersServiceMutation,
  useGetMonitorsCustomersMutation,
  useGetMonitorsMutation,
  useGetMonitorsOperatorsMutation,
  monitorsService,
  useGetMonitorsDownloadMutation,
  useDeleteMonitorsStampingMutation,
  usePutMonitorsEditMutation,
  usePostMonitorsAddMutation,
} from "features/storeManagement/services/monitorsService";
import { useParams } from "react-router-dom";
import ErrorBoundary from "features/ErrorBoundary";
import EmptyPageNote from "components/content/EmptyPageNote";
import { labels } from "features/locale/it-it";
import { useDispatch } from "react-redux";
import { format, isValid } from "date-fns";

const TimbratureTablePage = () => {
  const { customer, serviceAddress } = useParams();
  const dispatch = useDispatch();
  const [
    getMonitors,
    {
      data: monitorsData,
      isLoading: monitorsDataIsLoading,
      error: monitorsDataError,
    },
  ] = useGetMonitorsMutation({});

  const [
    getMonitorsCustomers,
    {
      data: monitorsCustomer,
      isLoading: monitorsCustomerIsLoading,
      error: monitorsCustomerError,
    },
  ] = useGetMonitorsCustomersMutation({
    fixedCacheKey: fixedCacheKeys.monitorsCustomer,
  });

  const [
    getMonitorsCustomersService,
    {
      data: monitorsCustomerService,
      isLoading: monitorsCustomerServiceIsLoading,
      error: monitorsCustomerServiceError,
    },
  ] = useGetMonitorsCustomersServiceMutation({
    fixedCacheKey: fixedCacheKeys.monitorsCustomerService,
  });

  const [
    getMonitorsOperators,
    {
      data: monitorsOperators,
      isLoading: monitorsOperatorsIsLoading,
      error: monitorsOperatorsError,
    },
  ] = useGetMonitorsOperatorsMutation({});

  const [
    downloadMonitors,
    {
      data: downloadMonitorsDataResponse,
      isLoading: downloadMonitorsIsLoading,
      error: downloadMonitorsError,
      isSuccess: downloadMonitorsisSuccess,
    },
  ] = useGetMonitorsDownloadMutation({});

  //to share error cache between components
  const [, { error: deleteMonitorsStampingError, reset: deleteMonitorsReset }] =
    useDeleteMonitorsStampingMutation({
      fixedCacheKey: fixedCacheKeys.deleteMonitorsStampingResponse,
    });
  const [, { error: putMonitorsEditError, reset: putMonitorsEditReset }] =
    usePutMonitorsEditMutation({
      fixedCacheKey: fixedCacheKeys.putMonitorsEditDataResponse,
    });
  const [, { error: postMonitorsAddError, reset: postMonitorsAddReset }] =
    usePostMonitorsAddMutation({
      fixedCacheKey: fixedCacheKeys.postMonitorsAddDataResponse,
    });

  const [hasUpdatedTimbrature, setHasUpdatedTimbrature] =
    useState<boolean>(false);
  const [optionsOperatori, setOptionsOperatori] = useState<string[]>([]);
  const [optionsServizio, setOptionsServizio] = useState<string[]>([]);
  const [optionsStatus, setOptionsStatus] = useState<string[]>([]);
  const [optionsCustomers, setOptionsCustomers] = useState<string[]>([]);
  const [optionsManagedManually, setOptionsManagedManually] = useState<
    string[]
  >(["Si", "No", "Entrambi"]);

  const [formDataCreatedDate, setFormDataCreatedDate] =
    useState<ChecklistFormStateType>({});
  const [formDataLastUpdated, setFormDataLastUpdated] =
    useState<ChecklistFormStateType>({});
  const [formDataOperatori, setFormDataOperatori] =
    useState<ChecklistFormStateType>({});
  const [formDataStatus, setFormDataStatus] = useState<ChecklistFormStateType>(
    {}
  );
  const [formDataCustomers, setFormDataCustomers] =
    useState<ChecklistFormStateType>({});
  const [formDataCustomersService, setFormDataCustomersService] =
    useState<ChecklistFormStateType>({});
  const [formManagedManually, setFormManagedManually] =
    useState<ChecklistFormStateType>({});

  const [customerIdFromSelect, setCustomerIdFromSelect] = useState<string>("");
  const [serviceAddressFromSelect, setServiceAddressFromSelect] =
    useState<string>("");
  const [operatorCidFromSelect, setOperatorCidFromSelect] =
    useState<string>("");

  const [showEmptyItems, setShowEmptyItems] = useState<boolean>(false);
  const [flagClear, setFlagClear] = useState<boolean>(false);

  const managedManuallyMapping: { Si: boolean; No: boolean; Entrambi: string } =
    {
      Si: true,
      No: false,
      Entrambi: "",
    };

  const statusMapping: { [key: string]: string } = {
    Scoperto: "pending",
    Incompleto: "in_progress",
    Coperto: "finished",
    "Coperto manualmente": "manual",
  };

  const monitorsBody = {
    startDate: "",
    endDate: "",
    pagination: false,
    // serviceAddress: serviceAddress ? serviceAddress : "",
    operatorCid: "",
    customerId: customer ? customer : "",
    page: 1,
  };

  const handleOperatorSelectClick = async () => {
    const dateObject = {
      startDate: formDataCreatedDate?.["keyPlaceSelect "]?.[0],
      endDate: formDataLastUpdated?.["keyPlaceSelect "]?.[0],
    };

    try {
      await getMonitorsOperators(dateObject);
    } catch (error) {
      console.error("Error fetching operators:", error);
    }
  };

  useEffect(() => {
    if (
      monitorsDataError ||
      deleteMonitorsStampingError ||
      putMonitorsEditError ||
      postMonitorsAddError
    ) {
      dispatch(monitorsService.util.resetApiState());
    }
  }, []);

  useEffect(() => {
    if (!monitorsCustomerIsLoading && !monitorsCustomer && !!customer) {
      getMonitorsCustomers();
    }
  }, [monitorsCustomer]);

  useEffect(() => {
    if (customerIdFromSelect) {
      getMonitorsCustomersService({ customerId: customerIdFromSelect });
    } else if (!customerIdFromSelect) {
      setFormDataCustomersService({});
    }
  }, [customerIdFromSelect]);

  //delete/post/put check if do the getmonitors after delete/post/put
  useEffect(() => {
    if (hasUpdatedTimbrature) {
      getMonitors(monitorsCercaDownloadBody);
      deleteMonitorsReset();
      putMonitorsEditReset();
      postMonitorsAddReset();
      setHasUpdatedTimbrature(false);
    }
  }, [hasUpdatedTimbrature]);

  //first time and flag clear
  useEffect(() => {
    getMonitors(monitorsBody);
  }, [flagClear]);

  //check if table is empty
  useEffect(() => {
    setShowEmptyItems(!!(monitorsData && monitorsData?.items.length === 0));
  }, [monitorsData]);

  //set default date
  useEffect(() => {
    if (monitorsData?.startDate && monitorsData?.endDate) {
      const optionDefaulStartDate = monitorsData.startDate;
      const optionDefaultEndDate = monitorsData.endDate;
      setFormDataCreatedDate({
        "keyPlaceSelect ": [optionDefaulStartDate],
      });
      setFormDataLastUpdated({
        "keyPlaceSelect ": [optionDefaultEndDate],
      });
    }
  }, [monitorsData?.startDate, monitorsData?.endDate]);

  //from the selected operator set the operator cid for the body
  useEffect(() => {
    if (monitorsOperators) {
      const operatorCidSelected = monitorsOperators.filter(
        (el) => el.name === formDataOperatori?.["keyPlaceSelect "]?.[0]
      );

      setOperatorCidFromSelect(operatorCidSelected[0]?.operatorCid);
    }
  }, [formDataOperatori]);

  //todo: utilizzare un useeffect per ogni select sotto dichiarate. attualmente va a valorizzare le options dei i filtri al primo accesso e ogni volta volta che viene chiamata la monitors
  useEffect(() => {
    if (monitorsData && monitorsCustomer) {
      const optionsOperatoriArr: string[] = [];
      const optionsStatusArr: string[] = [
        "Scoperto",
        "Incompleto",
        "Coperto",
        "Coperto manualmente",
      ];
      const optionsCustomersArr: {
        customerId: string;
        customerName: string;
      }[] = [];
      const optionsCustomerServiceArr: {
        serviceAddress: string;
        venueName: string;
      }[] = [];
      const optionsManagedManuallyArr: string[] = ["Si", "No", "Entrambi"];

      monitorsCustomer?.map(
        (customer: { customerName: string; customerId: string }) => {
          if (!optionsCustomersArr.includes(customer)) {
            optionsCustomersArr.push(customer);
          }
        }
      );

      if (formDataCustomers) {
        const customerIdSelected = optionsCustomersArr.filter(
          (el) =>
            el.customerName === formDataCustomers?.["keyPlaceSelect "]?.[0]
        );
        setCustomerIdFromSelect(customerIdSelected[0]?.customerId);
      }

      //options postazioni di servizio
      monitorsCustomerService?.map(
        (service: { serviceAddress: string; venueName: string }) => {
          if (!optionsCustomerServiceArr.includes(service)) {
            optionsCustomerServiceArr.push(service);
          }
        }
      );

      if (formDataCustomersService) {
        const serviceAddressSelected = optionsCustomerServiceArr.filter(
          (el) =>
            el.venueName === formDataCustomersService?.["keyPlaceSelect "]?.[0]
        );
        setServiceAddressFromSelect(serviceAddressSelected[0]?.serviceAddress);
      }

      if (monitorsOperators) {
        monitorsOperators?.map((el) => {
          {
            if (!optionsOperatoriArr.includes(el.name)) {
              optionsOperatoriArr.push(el.name);
            }
          }
        });
      }

      setOptionsCustomers(optionsCustomersArr.map((el) => el.customerName));
      setOptionsStatus(optionsStatusArr);
      setOptionsServizio(optionsCustomerServiceArr.map((el) => el.venueName));
      setOptionsManagedManually(optionsManagedManuallyArr);
      setOptionsOperatori(optionsOperatoriArr);
    }
  }, [
    monitorsData,
    monitorsCustomer,
    monitorsCustomerService,
    formDataCustomers,
    formDataCustomersService,
    monitorsOperators,
  ]);

  const handleClearFilter = () => {
    setFormDataCreatedDate({});
    setFormDataLastUpdated({});
    setFormDataOperatori({});
    setFormDataCustomers({});
    setFormDataCustomersService({});
    setFormDataStatus({});
    setFormManagedManually({});

    setFlagClear(!flagClear);
  };

  const selectedStatus = formDataStatus?.["keyPlaceSelect "]?.[0];
  const selectedStartDate = formDataCreatedDate?.["keyPlaceSelect "]?.[0];
  const selectedEndDate = formDataLastUpdated?.["keyPlaceSelect "]?.[0];

  const selectedManagedManually = formManagedManually?.[
    "keyPlaceSelect "
  ]?.[0] as "Si" | "No" | "Entrambi";

  const managedManuallyValue = managedManuallyMapping[selectedManagedManually];

  const statusValue = statusMapping[selectedStatus];

  const monitorsCercaDownloadBody = {
    startDate: selectedStartDate,
    endDate: selectedEndDate,
    pagination: false,
    customerId: customerIdFromSelect ? customerIdFromSelect : customer || "",
    serviceAddress: serviceAddressFromSelect,
    status: statusValue,
    isManual: managedManuallyValue,
    page: 1,
    operatorCid: operatorCidFromSelect,
  };

  const handleCerca = () => {
    getMonitors(monitorsCercaDownloadBody);
  };

  const handleDownload = async () => {
    const response = await downloadMonitors(monitorsCercaDownloadBody).unwrap();

    const url = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `export ${format(new Date(), "dd/MM/yyyy")}.xlsx`
    );
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <ErrorBoundary
      errorMessage={
        monitorsDataError ||
        downloadMonitorsError ||
        deleteMonitorsStampingError ||
        putMonitorsEditError ||
        postMonitorsAddError
      }
    >
      <Suspense
        isLoading={monitorsDataIsLoading || downloadMonitorsIsLoading}
        fullPage
      >
        <Container>
          <>
            <Row className="align-items-end g-3 justify-content-between">
              <Col xs={12} md={2} className="datepicker_timbrature_page">
                <Datepicker
                  {...datePickerPropsTimbrature({
                    formData: formDataCreatedDate,
                    setFormData: setFormDataCreatedDate,
                    validated: false,
                    answerArr: [],
                    subtitle: `${labels.tableFilterStartDate}`,
                    title: "",
                    showTimeSelect: false,
                    showIcon: true,
                  })}
                />
              </Col>
              <Col xs={12} md={2} className="datepicker_timbrature_page">
                <Datepicker
                  {...datePickerPropsTimbrature({
                    formData: formDataLastUpdated,
                    setFormData: setFormDataLastUpdated,
                    validated: false,
                    answerArr: [],
                    subtitle: `${labels.tableFilterEndDate}`,
                    title: "",
                    showTimeSelect: false,
                    showIcon: true,
                  })}
                />
              </Col>

              <Col xs={12} md={2}>
                <Select
                  {...selectPropsTimbrature({
                    formData: formDataCustomers,
                    setFormData: setFormDataCustomers,
                    validated: false,
                    answerArr: optionsCustomers,
                    subtitle: `${labels.tableFilterCustomers}`,
                    title: "",
                  })}
                />
              </Col>

              <Col xs={12} md={4}>
                <Select
                  {...selectPropsTimbrature({
                    formData: formDataCustomersService,
                    setFormData: setFormDataCustomersService,
                    validated: false,
                    answerArr: optionsServizio,
                    subtitle: `${labels.tableFilterCustomerService}`,
                    title: "",
                    enabled: !!customerIdFromSelect,
                  })}
                />
              </Col>

              <Col xs={12} md={2}>
                <Select
                  {...selectPropsTimbrature({
                    formData: formDataOperatori,
                    setFormData: setFormDataOperatori,
                    validated: false,
                    answerArr: monitorsOperatorsIsLoading
                      ? ["Is loading"]
                      : optionsOperatori,
                    subtitle: `${labels.tableFilterOperators}`,
                    title: "",
                    onClick: handleOperatorSelectClick,
                  })}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} lg={2}>
                <Select
                  {...selectPropsTimbrature({
                    formData: formDataStatus,
                    setFormData: setFormDataStatus,
                    validated: false,
                    answerArr: optionsStatus,
                    subtitle: `${labels.tableFilterStatus}`,
                    title: "",
                  })}
                />
              </Col>
              <Col xs={12} md={4} lg={2}>
                <Select
                  {...selectPropsTimbrature({
                    formData: formManagedManually,
                    setFormData: setFormManagedManually,
                    validated: false,
                    answerArr: optionsManagedManually,
                    subtitle: `${labels.tableFilterManagedManually}`,
                    title: "",
                  })}
                />
              </Col>

              <Col
                xs={6}
                md={4}
                lg={4}
                className="d-flex align-items-end gap-2 mt-sm-2 justify-content-lg-start justify-content-md-end"
              >
                <Button variant="primary" onClick={handleCerca}>
                  {labels.tableSearchButton}
                </Button>
                <Button variant="warning" onClick={handleClearFilter}>
                  {labels.tableClearButton}
                </Button>
              </Col>
              <Col className="d-sm-none d-none d-lg-block" md={2}></Col>
              <Col
                xs={6}
                md={2}
                lg={2}
                className="d-flex align-items-end justify-content-lg-end mt-sm-2 justify-content-md-between"
              >
                <Button variant="green" onClick={handleDownload}>
                  {labels.tableDownloadStampingExcel}
                </Button>
              </Col>
            </Row>

            {showEmptyItems && (
              <EmptyPageNote
                note={labels.tableNoItemsFound}
                isLoading={monitorsDataIsLoading}
              />
            )}

            {monitorsData &&
              !!monitorsData?.items.length &&
              !showEmptyItems && (
                <div className="my-3">
                  <TimbratureTable
                    data={monitorsData?.items || []}
                    setHasUpdatedTimbrature={setHasUpdatedTimbrature}
                  />
                </div>
              )}
          </>
        </Container>
      </Suspense>
    </ErrorBoundary>
  );
};

export default TimbratureTablePage;
