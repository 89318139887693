interface ILabels<T> {
    [id: string]: T;
}

export const labels : ILabels<string> = {

	/* Global */
	sicurforce : "Sicureforce",
	welcome : '%{firstname}% %{lastname}% ',
	pagetitle : '%{resource}% %{action}% %{view}%',
	welcomeRole : 'Benvenuto, il tuo ruolo è: ',
	showIcons : '-> Custom icon disponibili <-',
	sicurforceBrand : 'Sicurforce | Sicuritalia Ivri S.p.a',
	apriChiudiMenu : 'Espandi o riduci menu',
	yesLabel : 'Si',
	noLabel : 'No',

	monitorTimbrature: "Monitor Timbrature",
	checklistText: 'Checklist',
	patrolText: 'Patrol',
	incidentText: 'Incident',
	journalText: 'Journal',
	parcelText: 'Parcel',
	shiftCheckText: 'ShiftCheck',
	shiftText: 'Shift',
	controlloAccessi: 'Controllo accessi',
	keysText: 'Keys',
	labelEsci: 'Esci',

	turnieTimbratureText: 'TurniTimbrature',
	pianificazioneTurniText: 'PianificazioneTurni',
	turniCorrispondentiText: 'TurniCorrispondenti',
	auditText: 'Audit',
	rondeText: 'Ronde',
	giornaleDiServizioText: 'GiornaleServizio',
	eventiSegnalatiText: 'SegnalazioniEventi',
	eventiSegnalati: 'Segnalazioni Eventi',
	gestionePacchiePostaText: 'GestionePacchiPosta',
	gestioneChiaviText: 'GestioneChiavi',
	controlloTessereText: 'ControlloTessere',
	registroAttivitàText: 'RegistroAttivita',
	reportControlRoomText: 'ReportControlRoom',
	reportControlloQualita: 'ControlloQualita',
	reportAntiTaccheggio: 'AntiTaccheggio',
	reportControlloAccessi: 'ControlloAccessi',
	reportRegistroAccessi: 'RegistroAccessi',
	reportAllarmiIspettivi: 'AllarmiIspettivi',

	/* Login */
	username : "Username",
	password : "Password",
	wrongCredentials : "Le credenziali non sono corrette.",
	oopsThereWasAProblem : "C'è stato un problema. Riprova più tardi.",
	rememberMe : 'Ricordami',
	forgotPassword : 'Password dimenticata?',
	login : 'Accedi',
	clickForDetails: "Clicca per leggere i dettagli",
	iAccept: 'Accetto',
	logout : 'Log out',
	termsAndConditions: 'Termini e condizioni',
	changePassword: 'Cambia password',

	/* Checklist and Patrols */
	next: 'Successivo',
	previous : 'Precedente',
	submit: "Termina",
	saveAsDraft: "Salva in bozza",
	download: "Scarica",
	downloadPDF: "Scarica PDF",
	fieldCannotBeEmpty: "Il campo selezionato non può essere vuoto",
	mustChooseOne: "Seleziona un'opzione tra quelle disponibili",
	mustChooseADate: "Deve inserire un dato",
	mustCheck: "Seleziona un'opzione tra quelle disponibili",
	mustUploadPhoto: "È necessario caricare un'immagine",
	insertYourAnswer: "Inserisci la risposta",
	selectAnAnswer: "Seleziona",
	mustSelectOne: "Deve selezionare almeno una",
	confirm: "Conferma",
	cancel: "Annulla",
	sureToSubmit:"Sei sicuro di voler terminare la Checklist?",
	onceSubmittedCantEdit: "Confermando non potrai più modificare la Checklist",
	insertDate: "Inserisci la data",
	emptyAnswer: "--",
	lastUpdate: "Ultimo aggiornamento",
	startNewChecklist: 'Inizia la compilazione',
	startNewCompilation: 'Nuova compilazione',
	noChecklistYet: "Non sono ancora presenti delle compilazioni terminate o in corso",
	noExecutableChecklist: "Questa checklist non è eseguibile",
	noExecutablePatrol: "Questa ronda non è eseguibile",
	noPatrolsYet: "Non sono ancora presenti delle compilazioni terminate o in corso",
	forcedCloseNotes : "Note di chiusura",
	patrolFinishedAt: "Ronda terminata il",
	errorEmail: "Inserire un formato di mail valido",
	errorExistingEmail: "Questa mail risulta già registrata nel sistema",
	
	/* Signature*/
	notTabletDevice: "Questo dispositivo non è un Tablet",
	noSignatureLabel: "Firmare nel box in alto",

	/* Home */
	contactUs : "contattaci",

	/* Modules */
	shift: "Shift",
	dataInizio: 'Data inizio: ',
	oraInizio: 'Ora inizio: ',
	dataFine: 'Data fine: ',
	oraFine: 'Ora fine: ',
	checklistStart: 'Inizia',
	draftResume: 'Riprendi',
	inProgress: 'In corso',
	checklistRead: 'Leggi',
	checklist : "Checklist",
	checklists : "i Checklist",
	planning : "Planning",
	roleName: "Ruolo",
	moduleName: "Modulo",
	details: "Dettaglio",
	shiftDetails: "Dettaglio turno",
	goToHome: "Home",
	shiftCheckinTime: "Timbratura inizio turno",
	shiftCheckoutTime: "Timbratura fine turno",
	shiftStartTime: "Inizio turno",
	shiftEndTime: "Fine turno",
	notes: "Note",
	noActiveShift: "Non ci sono turni disponibili in questo momento",
	noActiveShiftDetail: "Questo turno non è più visibile via WEB. lo potrai consultare nello storico dell'applicazione.",
	notExecutableChecklist: "Questa checklist non è eseguibile",
	noModulesForShift: "Non ci sono moduli disponibili per questo turno",
	unstampedShift: "Per visualizzare i moduli devi effettuare la timbratura tramite l'APP e successivamente ricaricare la pagina web",
	shiftIsOver: "Questo turno è terminato. Non è più possibile accedere ai moduli abilitati",
	noCurrentShiftAndCustomers: "Al momento non ci sono turni associati per questa utenza",
	moduliTurno: 'Moduli abilitati al turno',
	usefulLinks: 'Link utili',
	textAggiungiNominativo: "Nome non presente in elenco?",
	textTitleSelect: "Selezionare la persona a cui verranno consegnate le chiavi:",
	/* Coordinatore */
	customer: 'Cliente',
	customerAddress: 'Indirizzo',
	customerSelectDefault: 'Scegli il cliente',
	addressSelectDefault: "Scegli l'indirizzo",
	tipologyServiceSelectDefault: "Scegli la tipologia",
	tipologyService: "Tipologia servizio",
	homeSubmit: 'Accedi',
	internalAudits: 'Audit/Checklist specifici',
	genericAudits: 'Audit/Checklist generici',
	recipientError: "recipient",

	// Responsabile
	noCustomersAvailable: 'Al momento non sono presenti clienti disponibili',

	/* Corrispondente */
	beginShift: "Inizia Turno",
	finishShift: "Fine Turno",
	cannnotModifyAfterConfirm: "Sei sicuro di chiudere il turno? Terminandolo non potrai più accedervi ed apportare modifiche.",
	closeWindow: "Chiudi",
	ok: "OK",
	watchOut: "Attenzione",
	deleteShift: "Cambia postazione",
	tryLaterPlease: "C'è stato un problema nella fase di accesso al turno. Riprova l’accesso oppure ritenta più tardi.",

	/* Audits */
	noAuditsAvailable: 'Al momento non sono disponibili degli Audit',
	accordionDetailCustomer: 'Indirizzo',
	accordionDetailContract: 'Contratto',
	accordionDetailPosition: 'Posizione',
	accordionVenueName: 'Postazione',

	/* Errors */
	nonAuthorzied: "Non Autorizzato",
	nonAuthorizedMessage: "Non sei autorizzato per accedere a questa pagina.",
	notFound : "Pagina non torvata",
	notFoundMessage: "La pagina non si è trovata.",
	upsThereWasAProblem:"Ops!",
	upsThereWasAProblemMessage:"C'è stato un problema",
	pleaseRetry : "Prova a ricaricare la pagina, o ricominciare la pratica.",
	ifProblemPersists:"Se il problema non si risolve, ",
	makeSureAddressIsRight: "Assicurati di aver inserito un'indirizzo valido.",
	ifThereIsAProblem : "Se c'è stato un problema",
	takeMeToHomepage : "Vai alla Homepage",

	/* Page Titles */
	pageTile_AuditPage: 'Dettaglio Audit',
	pageTile_ChecklistPage: 'Dettaglio Checklsit',
	pageTile_IncidentPage: 'Dettaglio Incident',
	pageTile_AuditItemsPage: 'Storico Audit',
	pageTile_ChecklistItemsPage: 'Storico Checklist',
	pageTile_PatrolItemsPage: 'Storico Ronde',
	pageTile_AdminPage: 'Pagina Admin',
	pageTile_ClientePage: 'Pagina Cliente',
	pageTile_CoordinatorePage: 'Pagina Coordinatore',
	pageTile_CoordinatoreDashboardPage: 'Dashboard Coordinatore',
	pageTile_CorrispondentePage: 'Pagina Corrispondente',
	pageTile_OperatorePage: 'Pagina Operatore',
	pageTile_IncindentManagementItemsPage: 'Storico Incident Management',
	pageTitle_DettaglioChecklist: 'Dettaglio Checklist',
	pageTitle_IncidentPage: 'Incident management',
	pageTitle_JournalPage: "Dettaglio Giornale di servizio",
	pageTitle_JournalItemsPage: "Storico Giornale di servizio",
	pageTile_ResponsabilePostazione: 'Pagina Responsabile postazione',
	pageTitle_ParcelItemsPage: "Storico Gestione Pacchi",
	pageTitle_ParcelPage: "Dettaglio Gestione Pacchi",
	pageTitle_KeysItemsPage: "Storico Gestione Chiavi",
	pageTitle_KeysPage: "Dettaglio Gestione Chiavi",
	pageTitle_Report: "Dettaglio Report",
	pageTitle_gestioneTurni: "Gestione Turni",
	pageTitle_gestioneTurno: "Dettaglio Gestione Turno",
	pageTitle_controlloAccessi: "Controllo Accessi",
	pageTitle_controlloAccessiDettaglio: "Dettaglio controllo accessi",
	pageTitle_storicoSegnalazioniControlloAccesso: "Storico segnalazione controllo accessi",
	pageTitle_areaDocumenti: "Area documenti",
	pageTitle_controlloAccessiDettaglioSegnalazione: "Dettaglio controllo accessisegnalazione",
	
	/* Journal */
	journal: "Giornale di servizio",
	journalStartTitle: 'Inizia la compilazione del giornale di servizio per il turno in corso',
	journalUpdateTitle: 'Aggiorna o modifica il giornale di servizio per il turno in corso',
	previousJournals: 'Giornali precedenti',
	startNewJournal: 'Inizia la compilazione',
	updateJournal: 'Modifica',
	save: "Salva",
	noJournalsYet: 'Attualmente non sono presenti delle compilazioni precedenti',
	modalJournalTitle: 'Attenzione',
	modalJournalBody: 'Sei sicuro di voler aggiornare/modificare il giornale di servizio?',
	modalJournalDeleteBody: 'Cancellando l’immagine/video verrà eliminata definitivamente. Confermi la cancellazione?',
	mediaAttachment: 'Allega una foto/video',
	journalRead: 'Leggi',
	journalFormReadMode: 'Note di servizio',
	journalFormWriteMode: 'Inserisci le note',
	fillInDate: 'Data/ora compilazione',
	operator: 'Operatore',
	viewMedia: 'Visualizza gli allegati',
	journalImage: "Immagine",
	journalImagePreview: "Preview immagine",
	journalVideoPreview: "Preview video",
	preview: "Anteprima",
	journalMediaErrorPopup: "Caricamento non effettuato. Non sono state rispettate le seguenti limitazioni consentite: Da 1 a 5 immagini di dimensione massima 2Mb, 1 video  di dimensione massima  20Mb",
	dimensioneError: "Dimensioni del file troppo grande",

	/*Incident*/
	noIncidentYet: "Non sono ancora presenti delle segnalazioni terminate o in corso",
	incidentTitle: "Incidenti",
	incidentFormTitle: "Sei sicuro di voler terminare la segnalazione?",
	incidentFormBody: "Confermando non potrai più modificare l’evento segnalato",
	startNewReport: 'Nuova segnalazione',
	modalErrorIncidentBody: 'C\'è stato un problema nella cancellazione del file. Riprovare più tardi. Se persiste richiedere assistenza.',
	/* Parcel */
	newParcelUser: 'Registrazione nuovo utente',
	parcel: "Gestione pacchi",
	textRadio: "L'utente è un corriere?",
	textName : "Nome",
	textCognome: "Cognome",
	textSocieta: "Società",
	textEmail: "Email",
	textTelefono: "Telefono",
	textNote: "Note aggiuntive",
	parcelAnnulla: "Annulla",
	parcelModal: "Modale pagina",
	parcelTitle: "Registrazione Pacchi",
	recipientLabel: "Da consegnare a",
	recipientPersonLabel: "Depositato da",
	recipientPersonRLabel: "Seleziona la persona che sta ritirando il pacco",
	storageLabel: "Stoccaggio",
	newRecipient: "Aggiungi nominativo",
	isCarrier: "isCarrier",
	carrier: "Carrier",
	person: "Person",
	parcelFirstname: "nome",
	parcelLastname: "cognome",
	parcelCompany: "azienda",
	parcelEmail: "email",
	parcelPhone: "telefono",
	parcelNote: "note",
	parcelRoomForm: "IT",
	parcelSelectWarehouse: "Seleziona il punto di deposito",
	parcelSelectKey: "parcelPlaceSelect",
	parcelDeliveryPersonKey: "parcelDeliveryPersonId",
	parcelRecipientPersonKey: "parcelRecipientPersonId",
	parcelButton: "Nuovo pacco",
	parcelDaConsegnare: "Da consegnare",
	parcelConsegnato: "Consegnato",
	parcelLost: "Perso",
	parcelStolen: "Rubato",
	parcelDeleted: "Cancellato",
	parcelLablesEmptyState: "Attualmente, non sono disponibili pacchi/posta in gestione alla portineria",
	parcelLablesEmptySearch: " corrispondenti a questi parametri di ricerca",
	parcelDestinatario: "Destinatario",
	parcelDestinatarioLabel: "Destinatario del pacco",
	parcelDepositarioLabel: "Persona o il corriere che ha depositato il pacco in portineria",
	parcelArrivatoIl: "Arrivato il",
	parcelConsegnatoIl: "Consegnato il",
	parcelStato: "Stato",
	parcelCercaPlaceholder: "Cerca un pacco in giacenza",
	parcelEmpty: "-",
	infoConsegna: "Informazioni di consegna",
	info_segnalazione: "Dettagli segnalazione",
	parcelStatoDelPacco: "Stato del pacco:",
	parcelNoteDiSegnalazione: "Note di segnalazione:",
	parcelOperatorePortineria: "Operatore portineria:",
	parcelRitiratoDa: "Ritirato da",
	parcelSegnalatoIl: "Segnalato il",
	recipient: "Destinatario",
	parcelDepositedBy: "Depositato da",
	porter: "portineria",
	arrivedDate: "Arrivato il",
	recipientSameDeliveredPerson: "La persona che sta ritirando il pacco corrisponde al destinatario",
	withdrawingPersonSignature: "Firma ritirante",
	parcelStatusLabel: "Segnala lo stato del pacco",
	reportNotes: "Note di segnalazione",
	reportNotesKey: "reportNotes",
	reportNotesKeySegnalazione: "reportNotesSegnalazione",
	parcelApriSegnalazione: "Apri segnalazione",
	parcelConsegnaPacco: "Consegna pacco",

	/**** Chiavi */
	keysButton: "Gestione chiavi",
	keyRecipientPersonId: "keyRecipientPersonId",
	titleRecipientYes: "Selezionare la persona a cui verranno consegnate le chiavi",
	titleRecipientNo: "Attenzione, la consegna è censita solamente agli utenti autorizzati riportanti nel seguente elenco:",
	codeKey: "Codice chiave",
	RequestAuthorizationKey: "Richiesta autorizzazione",
	descriptionKey: "Descrizione",
	historyKeysDelivery: "Storico consegne",
	deliverKeys: "Consegna chiavi",
	returnKeys: "Riconsegna chiavi",
	keyCodeLabel: "Codice chiave",
	keyDescriptionLabel: "Descrizione",
	keyDeliveredTheLabel: "Consegnate il",
	keyClosedLabel: "Data chiusura",
	keyDeliveredToLabel: "Consegnate a",
	keyStatusLabel: "Stato",
	keyStatusConsegna: "Consegna",
	keyStatusDisponibile: "Disponibile",
	keyStatusConsegnate: "Consegnate",
	keyStatusConsegnateA: "Consegnate a",
	keyStatusRiconsegna: "Riconsegna",
	keyLablesEmptyState: "Attualmente, non sono disponibili chiavi in gestione alla portineria.",
	keyStoricoEmptyState: "Attualmente, non sono disponibili consegne terminate da poter consultare. Per tornare alla gestione delle chiavi seleziona il pulsante Consegna chiavi.",
	keyLablesNoResultState: "Non è stato trovato alcun risultato.",
	keyStatusBloccata: "Bloccata",
	deliveryKeys: "Consegna chiavi",
	keySelectKey: "keyPlaceSelect",
	keyPersonLabel: "Seleziona la persona che ha riconsegnato le chiavi",
	KeySameDeliveredPerson: "Riconsegnate da",
	keyReport: "Causale segnalazione chiavi",
	keyIsSamePerson: "isSamePerson ",
	keySubtitleRadioProps: "La persona che ha riportato le chiavi in portineria è la stessa della consegna",
	keyLabelFrom: "Dal",
	keyDeliveredTo: "Selezionare la persona a cui verranno consegnate le chiavi:",
	keyDeliveredAuthorizationRequiredTo: "Attenzione, la consegna è consentita solamente agli utenti autorizzati riportati nel seguente elenco:",
	keyStatus: "Causale segnalazione chiavi",
	infoKeyStatusBloccata: "Chiavi bloccate",
	infoKeyStatusConsegnata: "Informazioni di riconsegna",
	descriptionKeyStatusBloccata: "Causale segnalazione:",
	dateKeyStatusBloccata: "Segnalato il:",
	noteKeyStatusBloccata: "Note di segnalazione:",
	noteKeyStatusRiconsegna: "Note di riconsegna:",
	operatorKeyStatusBloccata: "Operatore portineria:",
	dateKeyStatusConsegnate: "Consegnate il:",
	dateKeyStatusRiconsegnate: "Riconsegnate il:",
	operatorKeyStatusConsegnate: "Operatore portineria:",
	withdrawnfromKeyStatusConsegnate: "Riconsegnate da:",
	keyNotesRiconsegna: "Note di riconsegna",
	KeyNotesDelivery: "Note di consegna",
	keyBodyAlertDelivery: "Bloccando le chiavi non sarà più possibile avviare la consegna.",
	keyBodyAlertRiconsegna: "Bloccando le chiavi verrà terminata forzatamente la consegna per il problema segnalato.",
	keyTitleAlertRiconsegna: "Attenzione, le chiavi sono in consegna",
	textButtonKeyModal: "Blocca chiavi",
	keyStatusDelivered: "Delivered",
	keyStatusStored: "Stored",
	keyCercaPlaceholder: "Cerca una chiave",
	buttonSbloccaChiavi: "Sblocca chiavi",

	/*Turno*/
	textLabelOperatore: "Operatore assegnato:",
	operatorPopupTitle: "Operatore cambiato con successo",
	operatorPopupBodyLabel: "Il cambio operatore è stato eseguito correttamente solo su SICURFORCE.",
	operatorPopupBodyAttenzione: "ATTENZIONE:",
	operatorPopupBodyRicordati: "Ricordati che è",
	operatorPopupBodyNecessarioBold: "necessario",
	operatorPopupBodyCambio: "fare questo cambio anche sul modulo",
	operatorPopupBodySapBold: "SAP MRS FIORI!",
	textNewOperator: "Cerca il nuovo operatore da assegnare al turno:",

	/*Area Documenti*/
	documentsArea: "Area documenti",
	noDocuments: "Al momento non sono presenti documenti",
	noSearchResultsDocuments: "Attualmente non sono disponibili documenti che corrispondono a questi parametri di ricerca",
	word: "Word",
	pdf: "Pdf",
	powerpoint: "PowerPoint",
	image: "Image",
	video: "Video",
	excel: "Excel",
	csv: "Csv",
	fileText: "File",
	dataCaricamento: "Data caricamento",
	searchDocumentPlaceholder: "Cerca un documento",

	/*Area Report*/
	reportAreaText: "Area Report",
	emptyPageTurni: "Non sono presenti turni nelle prossime 24h per questa postazione",

	/*Le mie attività*/
	myActivitiesText: "Le mie attività",
	auditChecklistItemDescription: "Audit/Checklist",
	gestioneTurniDescription: "Gestione turni",

	// Generics
	cf: "CF",
	cid: "CID",
	azienda: "Azienda",
	ingresso: "Ingresso",
	uscita: "Uscita",
	gestione: "Gestione",
	funzione: "Funzione",
	assignShift: 'Assegna turno',
	select: "Seleziona",
	search: "Cerca",
	picture: "picture",
	warning: "Attenzione",
	pianoText: "Piano",
	edificioText: "Edificio",
	stanzaText: "Stanza",
	scaffaleText: "Scaffale",
	ripianoText: "Ripiano",
	jobDescriptionLink: "Mansionario operativo",
	read: "Leggi",
	registro: "Registro accessi",
	aggiungi: "Aggiungi",
	pivaText: "P.Iva",
	author: "Autore",
	// Operatori
	noResultTabellaOperatori: "Non sono presenti operatori con queste specifiche",
	statusAnomaly: "Anomaly",
	statusEnabled: "Enabled",
	statusDisabled: "Disabled",
	statusBlocked: "Blocked",
	mandatoryField: "Campo obbligatorio",
	wrongField: "Campo errato",
	codiceFiscaleText: "Codice Fiscale",

	// Controllo Accessi
	addGuestTitle: "Utente aggiunto",
	addGuestConfirmBody: "Ti confermiamo che il nuovo utente è stato aggiunto all’elenco. Ora è possibile effettuare la sua gestione",
	duplicateGuestModalBody: "L'utente che si desidera aggiungere risulta già registrato nell'elenco",
	guest: "guest",
	noResultTabellaControlloAccessi: "Non sono presenti utenti con queste specifiche",
	invioSegnalazioneModalHeader: "Invio eseguito",
	invioSegnalazioneModalBody: "La segnalazione è stata registrata ed inviata correttamente",
	controlloAccessiBlockMessage: "L’utente selezionato risulta all’interno della sede. Confermando il blocco verrà registrata in automatico anche la sua uscita.",
	controlloAccessiSbloccaHeader: "Sblocco confermato",
	controlloAccessiSbloccaBody: "Le chiavi selezionate sono state sbloccate con successo.",
	controlloAccessiLabelImmagineProfilo: "Immagine profilo",
	// Menu
	menuAuditChecklist: "Audit/Checklist",
	menuChecklist: 'Checklist',
	menuPatrol: "Ronde",
	menuIncident: "Segnalazione evento",
	menuJournal: "Giornale di servizio",
	menuParcelManagement: "Gestione pacchi",
	menuKeysManagement: "Gestione chiavi",

	//Controllo Accesso

	dettaglioUtenteLabel: "Dettaglio utente",
	datiUtenteLabel: "Dati utente",
	tipologiaLabel: "Tipologia",
	datiSchedaLabel: "Dati tessera",
	codiceLabel: "Codice",
	tipologiaAttivitaLabel: "Tipologia attività",
	statoLabel: "Stato",
	ingressiLabel: "Numero ingressi residui",
	scadenzaLabel: "Scadenza",
	classeLabel: "Classe",
	datiAccessoLabel: "Dati accesso",
	ingressoProgrammatoLabel: "Ingresso programmato",
	ingressoRegistratoLabel: "Ingresso registrato",
	uscitaProgrammata: "Uscita programmata",
	uscitaRegistrata: "Uscita registrata",
	stateAttiva: "ATTIVA",
	stateDisattiva: "DISATTIVA",
	veicoliLabel: "Veicoli",
	segnalazioneLabel: "Segnalazione",
	registraIngressoLabel: "Registra ingresso",
	registraUscitaLabel: "Registra uscita",
	storicoSegnalazioniLabel: "Storico segnalazioni",
	noteAccessoLabel: "Note di accesso",
	mediaLabel: "Foto e video",
	controlloAccessiNote: "ControlloAccessiNote",
	disabilitatoLabel: "DISABILITATO",
	abilitatoLabel: "ABILITATO",
	bloccatoLabel: "BLOCCATO",
	modalControlloAccessiEntryHeader: "Ingresso Registrato",
	modalControlloAccessiExitHeader: "Uscita Registrata",
	modalControlloAccessiEntryBody: "L'ingresso dell'utente è stato registrato correttamente.",
	modalControlloAccessiExitBody: "L'uscita dell'utente è stata registrata correttamente.",
	noStoricoSegnalazioniYet: "Non sono presenti segnalazioni per questo utente.",
	controlloAccessiType: "controlloAccessi",
	risultatoVerifica: "Risultato della verifica",
	bloccaUtenteBottone: "Blocca utente",
	sbloccaUtenteBottone: "Sblocca utente",

	// Timbrature page

	operatoreCausale: "operatoreCausale",
	operatoreNote: "operatoreNote",
	tableNoItemsFound: "Non sono presenti turni con i parametri di ricerca inseriti",
	tableClearButton: "Pulisci",
	tableSearchButton: "Cerca",
	tableDownloadStampingExcel: "Esporta",
	tableFilterStartDate: "Data inizio pian.",
	tableFilterEndDate: "Data fine pian.",
	tableFilterOperators: "Operatori",
	tableFilterStatus: "Stato",
	tableFilterManagedManually: "Gestiti Manualmente",
	tableFilterCustomers: "Cliente",
	tableFilterCustomerService: "Destinazione",
	tableMonitorsContract: "Contratto",
	tableMonitorsService: "Servizio",
	tableMonitorsDestination: "Destinazione",
	tableMonitorsPosition: "Posizione",
	tableMonitorsRequirement: "Fabbisogno",
	tableMonitorsOperator: "Operatore",
	tableMonitorsCoverage: "Copertura",
	tableMonitorsStatus: "Status",
	tableMonitorsManagement: "Gestione",
	tableMonitorsAddDelete: "Duplica/Rimuovi",
	tableMonitorsStartStamping: "Timbratura Inizio Turno",
	tableMonitorsEndStamping: "Timbratura Fine Turno",
	modalMonitorsStartStampingEffective: "Timbratura Inizio Turno Effettiva",
	modalMonitorsEndStampingEffective: "Timbratura Fine Turno Effettiva",
	modalMonitorsStartStampingManual: "Timbratura Inizio Turno Manuale",
	modalMonitorsEndStampingManual: "Timbratura Fine Turno Manuale",
	modalCheckDateMissing: "Mancante",
	modalCheckInCausal: "Causale Inizio Turno",
	modalCheckOutCausal: "Causale Fine Turno",
	modalCheckInNote: "Note Inizio Turno",
	modalCheckOutNote: "Note Fine Turno",
	modalTextButtonDeleteStamping: "Cancella Timbratura",
	modalInfoEditFooter: "* Le timbrature manuali devono essere giustificate compilando i rispettivi campi di Causale e Note.",
	modalInfoAddRemoveFooter: "* Le timbrature manuali devono essere giustificate compilando i rispettivi campi di Causale e Note. In fase di aggiungi turno la timbratura manuale di inizio è obbligatoria.",
	modalSureToDeleteStamping:"Sei sicuro di voler cancellare la Timbratura?",
}

export const getLabel = (key: string | number, placeholders : {i : string, v: string}[]) => {
	let label = labels[key];

	placeholders.forEach((placeholder) => {
		label = label.replace(`%{${placeholder.i}}%`, placeholder.v);
	})

	return label;
}
