import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Error404 from "components/content/errors/Error404";
import Error500 from "components/content/errors/Error500";

import LoginPage from "components/authentication/Login/LoginPage";
import LogoutPage from "components/authentication/Logout/LogoutPage";
import RegistrationPage from "components/authentication/Registration/RegistrationPage";
import ForgetPasswordPage from "components/authentication/ForgetPassword/ForgetPasswordPage";
import PasswordResetPage from "components/authentication/PasswordReset/PasswordResetPage";
import ConfirmMailPage from "components/authentication/ConfirmMail/ConfirmMailPage";
import LockScreenPage from "components/authentication/LockScreen/LockScreenPage";
import ModalAuth from "components/authentication/Modals/ModalAuth";

import Profile from "theme/components/pages/user/profile/Profile";
import ActivityLog from "theme/components/app/social/activity-log/ActivityLog";
import Followers from "theme/components/app/social/followers/Followers";
import EventList from "theme/components/app/events/event-list/EventList";
import EventDetail from "theme/components/app/events/event-detail/EventDetail";
import CreateEvent from "theme/components/app/events/create-an-event/CreateEvent";

import ErrorLayout from "./ErrorLayout";
import MainLayout from "./MainLayout";
import Starter from "../theme/components/pages/Starter";
import { useLayout } from "./useLayout";
import PrivacyPage from "components/authentication/PrivacyPage";
import ShiftPage from "components/pages/ShiftPage";
import ChecklistPage from "components/pages/ChecklistPage";
import ChecklistItemsPage from "components/pages/ChecklistItemsPage";
import PatrolDetailsPage from "components/pages/PatrolDetailsPage";
import AuditItemsPage from "components/pages/AuditItemsPage";
import AuditModulesPage from "components/pages/AuditModulesPage";
import AuditPage from "components/pages/AuditPage";
import PatrolItemsPage from "components/pages/PatrolItemsPage";
import ServiceModulePage from "components/pages/ServiceModulePage";
import SignaturePage from "components/pages/SignaturePage/SignaturePage";
import IncidentItemsPage from "components/pages/IncidentItemPage/IncidentItemsPage";
import IncidentPage from "components/pages/IncidentPage/IncidentPage";
import JournalItemPage from "components/pages/JournalItemPage";
import JournalPage from "components/pages/Journal";
import ParcelItemNewPage from "components/pages/ParcelItemNewPage";
import ParcelDetailsPage from "components/pages/ParcelDetailsPage/ParcelDetailsPage";
import ReportTestPage from "components/pages/ReportTestPage";
import ParcelConsultation from "components/pages/ParcelConsultation/ParcelConsultation";
import ParcelDeliveryPage from "components/pages/ParcelDeliveryPage";
import KeyDeliveredPage from "components/pages/Key/KeyDeliveryPage/KeyDeliveredPage";
import KeyDeliveryPage from "components/pages/Key/KeyDeliveryPage/KeyDeliveryPage";
import KeysItemPage from "components/pages/KeysItemPage/KeysItemPage";
import KeyRiconsegnaPage from "components/pages/Key/KeyRiconsegnaPage/KeyRiconsegnaPage";
import KeyBloccataPage from "components/pages/Key/KeyBloccataPage/KeyBloccataPage";
import MediaUploadPage from "components/pages/ImageUploadPage";
import ReportPage from "components/pages/ReportPage/ReportPage";
import CoordinatoreDashboardPage from "components/pages/HomePages/Coordinatore/CoordinatoreDashboardPage";
import GestioneTurniPage from "components/pages/GestioneTurniPage/GestioneTurniPage";
import TurnoPage from "components/pages/TurnoPage/TurnoPage";
import ResponsabileDashboardPage from "components/pages/HomePages/Resposnabile/Dashboard/ResponsabileDashboardPage";
import ChecklistModulePage from "components/pages/ChecklistModulePage/ChecklistModulePage";
import KeyResponsabileItemPage from "components/pages/KeysItemPage/KeyResponsabileItemPage";
import KeyRiconsegnaResponsabilePage from "components/pages/Key/KeyRiconsegnaPage/KeyRiconsegnaResponsabilePage";
import KeyDeliveryResponsabilePage from "components/pages/Key/KeyDeliveryPage/KeyDeliveryResponsabilePage";
import IncidentModulePage from "components/pages/IncidentModulePage/IncidentModulePage";
import ResponsabileIncidentItemsPage from "components/pages/IncidentItemPage/ResponsabileIncidenteItemsPage";
import ResponsabileIncidentPage from "components/pages/IncidentPage/ResponsabileIncidentPage";
import KeyBloccataResponsabilePage from "components/pages/Key/KeyBloccataPage/KeyBloccataResponsabilePage";
import KeyDeliveredResponsabilePage from "components/pages/Key/KeyDeliveryPage/KeyDeliveredResponsabilePage";
import ResponsabileParcelDetailPage from "components/pages/ParcelDetailsPage/ResponsabileParcelDetailPage";
import ResponsabileParcelItemNewPage from "components/pages/ParcelItemNewPage/ResponsabileParcelItemNewPage";
import ResponsabileParcelDeliveryPage from "components/pages/ParcelDeliveryPage/ResponsabileParcelDeliveryPage";
import ResponsabileParcelConsultation from "components/pages/ParcelConsultation/ResponsabileParcelConsultation";
import JournalItemResponsabilePage from "components/pages/JournalItemPage/JournalItemResponsabilePage";
import ControlloAccessiPage from "components/pages/ControlloAccessiPage/ControlloAccessiPage";
import CustomerModulePage from "components/pages/CustomerModulePage/CustomerModulePage";
import ControlloAccessiDettaglioPage from "components/pages/ControlloAccessiDettaglioPage/ControlloAccessiDettaglioPage";
import StoricoSegnalazioniPage from "components/pages/StoricoSegnalazioniPage/StoricoSegnalazioniPage";
import ControlloAccessiDettaglioSegnalazionePage from "components/pages/ControlloAccessiDettaglioSegnalazionePage/ControlloAccessiDettaglioSegnalazionePage";
import AreaDocumentiPage from "components/pages/DocumentsPage/AreaDocumentiPage";
import TipologiaPage from "components/pages/TipologiaPage/TipologiaPage";
import TimbratureTablePage from "components/pages/TimbratureTablePage/TimbratureTablePage";

const Layout: FC = function Layout() {
	const { homePageForUser } = useLayout();
	const group = sessionStorage.getItem('primaryGroup') || localStorage.getItem('primaryGroup');

	return (
		<>
			<Routes>
				<Route element={<ErrorLayout />}>
					<Route path="errors/404" element={<Error404 />} />
					<Route path="errors/500" element={<Error500 />} />
				</Route>
				{/* - ------------- Authentication ---------------------------  */}

				{/* - ------------- Split ---------------------------  */}
				<Route path="authentication/login" element={<LoginPage />} />
				<Route path="authentication/logout" element={<LogoutPage />} />
				<Route path="authentication/register" element={<RegistrationPage />} />
				<Route
					path="authentication/forgot-password"
					element={<ForgetPasswordPage />}
				/>
				<Route
					path="authentication/reset-password"
					element={<PasswordResetPage />}
				/>
				<Route
					path="authentication/confirm-mail"
					element={<ConfirmMailPage />}
				/>
				<Route path="authentication/lock-screen" element={<LockScreenPage />} />

				{/* //--- MainLayout Starts  */}

				<Route element={<MainLayout />}>
					{/* HomePage */}

					{
						<Route
							key={homePageForUser?.label}
							path={"/"}
							element={homePageForUser?.component}
						/>
					}

					{<Route path="/login" element={<LoginPage />} />}
					{<Route path="/signature-test" element={<SignaturePage />} />}
					<Route path="/image-test" element={<MediaUploadPage />} />

					<Route path="social/activity-log" element={<ActivityLog />} />
					<Route path="social/followers" element={<Followers />} />
					<Route path="events/event-detail" element={<EventDetail />} />
					<Route path="events/event-list" element={<EventList />} />
					<Route path="events/create-an-event" element={<CreateEvent />} />

					<Route path="pages/starter" element={<Starter />} />
					<Route path="user/profile" element={<Profile />} />
					{/*

          {/* Components */}

					<Route path="forms/advance/file-uploader" />

					{/* Documentation */}
					<Route path="authentication-modal" element={<ModalAuth />} />

					{/* Terms and conditions */}
					<Route
						path="/terms-and-conditions"
						element={<PrivacyPage canAccept={false} />}
					/>

					{/* Plannings, Shifts and Checklists */}
					<Route path="shift/:shiftId" element={<ShiftPage />} />
					<Route
						path="shift/:shiftId/checklist/:checklistId"
						element={<ChecklistItemsPage />}
					/>
					<Route
						path="shift/:shiftId/checklist/:checklistId/new"
						element={<ChecklistPage />}
					/>
					<Route
						path="shift/:shiftId/checklist/:checklistId/draft/:draftId"
						element={<ChecklistPage />}
					/>
					<Route
						path="shift/:shiftId/checklist/:checklistId/read/:draftId"
						element={<ChecklistPage />}
					/>
					<Route
						path="shift/:shiftId/patrol/:patrolId"
						element={<PatrolItemsPage />}
					/>
					<Route
						path="shift/:shiftId/patrol/:patrolId/read/:draftId"
						element={<PatrolDetailsPage status="completed" />}
					/>
					<Route
						path="shift/:shiftId/patrol/:patrolId/in-progress/:draftId"
						element={<PatrolDetailsPage status="in-progress" />}
					/>

					{/* Plannings, Audit and Checklists */}
					<Route path="audit/list" element={<AuditModulesPage />} />
					<Route
						path="audit/list/customer/:customer/serviceAddress/:serviceAddress"
						element={<AuditModulesPage />}
					/>
					<Route
						path="checklist/list/customer/:customer/serviceAddress/:serviceAddress"
						element={<ChecklistModulePage />}
					/>
					<Route
						path="incident/list/customer/:customer/serviceAddress/:serviceAddress"
						element={<IncidentModulePage />}
					/>
					<Route path="audit/:auditId" element={<AuditItemsPage />} />
					<Route
						path="audit/:auditId/customer/:customer/serviceAddress/:serviceAddress/internal/:internal"
						element={<AuditItemsPage />}
					/>
					<Route
						path="checklist/:auditId/customer/:customer/serviceAddress/:serviceAddress"
						element={<AuditItemsPage />}
					/>

					<Route path="dashboard" element={<CoordinatoreDashboardPage />} />
					<Route
						path="dashboard/customer/:customer/serviceAddress/:serviceAddress"
						element={<CoordinatoreDashboardPage />}
					/>

					<Route path="audit/:auditId/new" element={<AuditPage />} />
					<Route path="audit/:auditId/draft/:draftId" element={<AuditPage />} />
					<Route path="audit/:auditId/read/:draftId" element={<AuditPage />} />
					<Route
						path="audit/:auditId/customer/:customer/serviceAddress/:serviceAddress/internal/:internal/new"
						element={<AuditPage />}
					/>
					<Route
						path="checklist/:auditId/customer/:customer/serviceAddress/:serviceAddress/new"
						element={<AuditPage />}
					/>
					<Route
						path="audit/:auditId/customer/:customer/serviceAddress/:serviceAddress/internal/:internal/draft/:draftId"
						element={<AuditPage />}
					/>
					<Route
						path="checklist/:auditId/customer/:customer/serviceAddress/:serviceAddress/draft/:draftId"
						element={<AuditPage />}
					/>
					<Route
						path="audit/:auditId/customer/:customer/serviceAddress/:serviceAddress/internal/:internal/read/:draftId"
						element={<AuditPage />}
					/>
					<Route
						path="checklist/:auditId/customer/:customer/serviceAddress/:serviceAddress/read/:draftId"
						element={<AuditPage />}
					/>
					{/* <Route path='customer/:customer/contract/:contract/position/:position/audit/:auditId' element={<AuditDetailsPage />} /> */}
					{/* {<Route path='shift/:shiftId/customer/:customer/contract/:contract/position/:position' element={<AuditPage />} />} */}

					<Route path="shift/:shiftId/customer/:customer" element={<ServiceModulePage />} />
					

					
					{/* <Route path='shift/:shiftId/customer/:customer/contract/:contract/position/:position/checklist/:checklistId/draft/new' element={<ChecklistPage/>} /> */}
					{/* <Route path='shift/:shiftId/customer/:customer/contract/:contract/position/:position/checklist/:checklistId/draft/:draftId' element={<ChecklistPage/>}/> */}
					{/* <Route path='shift/:shiftId/customer/:customer/contract/:contract/position/:position/checklist/:checklistId/read/:draftId' element={<ChecklistPage/>}/> */}

					<Route path="customer/:customerId/shiftList" 
							element={<TipologiaPage />}
					/>

					<Route
						path="shift/:shiftId/incident/:incidentId"
						element={<IncidentItemsPage />}
					/>
					<Route
						path="incident/:incidentId/customer/:customer/serviceAddress/:serviceAddress"
						element={<ResponsabileIncidentItemsPage />}
					/>
					<Route
						path="shift/:shiftId/incident/:incidentId/new"
						element={<IncidentPage />}
					/>
					<Route
						path="incident/:incidentId/customer/:customer/serviceAddress/:serviceAddress/new"
						element={<ResponsabileIncidentPage />}
					/>
					<Route
						path="shift/:shiftId/incident/:incidentId/draft/:draftId"
						element={<IncidentPage />}
					/>
					<Route
						path="incident/:incidentId/customer/:customer/serviceAddress/:serviceAddress/draft/:draftId"
						element={<ResponsabileIncidentPage />}
					/>
					<Route
						path="shift/:shiftId/incident/:incidentId/read/:draftId"
						element={<IncidentPage />}
					/>
					<Route
						path="incident/:incidentId/customer/:customer/serviceAddress/:serviceAddress/read/:draftId"
						element={<ResponsabileIncidentPage />}
					/>
					{/* 
					<Route path='audit/:auditId/read/:draftId' element={<IncidentPage />} />*/}

					{/* Sidebar Components */}

					{/* Timbrature Components **creare path per responsabile??** */}

					<Route path="shift/:shiftId/timbrature" element={<TimbratureTablePage />} />
					<Route
						path="timbrature/customer/:customer/serviceAddress/:serviceAddress"
						element={<TimbratureTablePage />}
					/>

					{/* Journal Components */}
					<Route path="shift/:shiftId/journal" element={<JournalItemPage />} />

					<Route
						path="journal/customer/:customer/serviceAddress/:serviceAddress"
						element={<JournalItemResponsabilePage />}
					/>

					<Route
						path="shift/:shiftId/journal/:journalId/:journalLogId/read"
						element={<JournalPage read={true} />}
					/>

					<Route
						path="journal/:journalId/:journalLogId/customer/:customer/serviceAddress/:serviceAddress/read"
						element={<JournalPage read={true} />}
					/>

					<Route
						path="shift/:shiftId/journal/:journalId/new"
						element={<JournalPage />}
					/>
					<Route
						path="journal/:journalId/new/customer/:customer/serviceAddress/:serviceAddress"
						element={<JournalPage />}
					/>
					<Route
						path="shift/:shiftId/journal/:journalId/draft/:draftId"
						element={<JournalPage />}
					/>
					<Route
						path="journal/:journalId/draft/:draftId/customer/:customer/serviceAddress/:serviceAddress"
						element={<JournalPage />}
					/>
					{/*Parcel*/}
					<Route path="shift/:shiftId/parcel" element={<ParcelDetailsPage />} />
					<Route
						path="parcel/:parcelId/customer/:customer/serviceAddress/:serviceAddress"
						element={<ResponsabileParcelDetailPage />}
					/>
					<Route
						path="shift/:shiftId/parcel/new"
						element={<ParcelItemNewPage />}
					/>
					<Route
						path="parcel/:parcelId/customer/:customer/serviceAddress/:serviceAddress/new"
						element={<ResponsabileParcelItemNewPage />}
					/>

					{/* PowerBI report */}
					<Route path="report-test" element={<ReportTestPage />} />

					{/*Turni*/}
					<Route
						path="turni/customer/:customer/serviceAddress/:serviceAddress"
						element={<GestioneTurniPage />}
					/>
					<Route
						path="turno/customer/:customer/shiftId/:shiftId/serviceAddress/:serviceAddress"
						element={<TurnoPage />}
					/>

					<Route
						path="shift/:shiftId/parcel/:parcelId/:parcelManagementId/:answerId/read"
						element={<ParcelConsultation />}
					/>
					<Route
						path="parcel/:parcelId/customer/:customer/serviceAddress/:serviceAddress/:parcelManagementId/:answerId/read"
						element={<ResponsabileParcelConsultation />}
					/>
					<Route
						path="shift/:shiftId/parcel/:parcelId/:parcelManagementId/:answerId/delivery"
						element={<ParcelDeliveryPage />}
					/>
					<Route
						path="parcel/:parcelId/customer/:customer/serviceAddress/:serviceAddress/:parcelManagementId/:answerId/delivery"
						element={<ResponsabileParcelDeliveryPage />}
					/>
					{/* Key */}
					<Route path="shift/:shiftId/keys" element={<KeysItemPage />} />

					<Route
						path="keys/customer/:customer/keyManagementId/:keyManagementId/serviceAddress/:serviceAddress"
						element={<KeyResponsabileItemPage />}
					/>
					<Route
						path="shift/:shiftId/keys/:keyManagementId/:keyRegistryId/delivery"
						element={<KeyDeliveryPage />}
					/>
					<Route
						path="keys/customer/:customer/keyManagementId/:keyManagementId/serviceAddress/:serviceAddress/delivery/keyRegistryId/:keyRegistryId/keyRegistryManagementId/:keyRegistryManagementId"
						element={<KeyDeliveryResponsabilePage />}
					/>
					<Route
						path="shift/:shiftId/keys/:keyManagementId/:keyRegistryManagementId/:keyRegistryId/answerId/:answerId/riconsegna"
						element={<KeyRiconsegnaPage />}
					/>

					<Route
						path="keys/customer/:customer/keyManagementId/:keyManagementId/serviceAddress/:serviceAddress/riconsegna/keyRegistryId/:keyRegistryId/keyRegistryManagementId/:keyRegistryManagementId/answerId/:answerId"
						element={<KeyRiconsegnaResponsabilePage />}
					/>
					<Route
						path="shift/:shiftId/keys/:keyManagementId/:keyRegistryManagementId/answerId/:answerId/bloccata"
						element={<KeyBloccataPage />}
					/>
					<Route
						path="keys/customer/:customer/keyManagementId/:keyManagementId/serviceAddress/:serviceAddress/bloccata/keyRegistryId/:keyRegistryId/keyRegistryManagementId/:keyRegistryManagementId/answerId/:answerId"
						element={<KeyBloccataResponsabilePage />}
					/>
					<Route
						path="shift/:shiftId/keys/:keyManagementId/:keyRegistryManagementId/answerId/:answerId/delivered"
						element={<KeyDeliveredPage />}
					/>

					<Route
						path="keys/customer/:customer/keyManagementId/:keyManagementId/serviceAddress/:serviceAddress/delivered/keyRegistryId/:keyRegistryId/keyRegistryManagementId/:keyRegistryManagementId/answerId/:answerId"
						element={<KeyDeliveredResponsabilePage />}
					/>

					<Route
						path="controlloAccessi"
						element={<ControlloAccessiPage />}
					/>

					<Route
						path="documents/customer/:customer/serviceAddress/:serviceAddress"
						element={<AreaDocumentiPage />}
					/>

					<Route
						path="shift/:shiftId/documents"
						element={<AreaDocumentiPage />}
					/>

					<Route
						path="documents"
						element={<AreaDocumentiPage />}
					/>

					{/* Report */}
					<Route path="/shift/report/:id" element={<ReportPage />} />
					<Route
						path="/report/:reportId/customer/:customer/serviceAddress/:serviceAddress"
						element={<ReportPage />}
					/>
					<Route
						path="/report/:reportId/customer/:customer/serviceAddress/:serviceAddress"
						element={<ReportPage />}
					/>
					<Route path="/report/:reportId" element={<ReportPage />} />

					{/* Power Bi */}
					<Route
						path="shift/:shiftId/powerBi/parcel/:parcelId/:parcelManagementId/:answerId/read"
						element={<ParcelConsultation />}
					/>

					<Route
						path="powerBi/parcel/:parcelId/:parcelManagementId/:answerId/read"
						element={<ResponsabileParcelConsultation />}
					/>

					<Route
						path="shift/:shiftId/powerBi/checklist/:checklistId/read/:draftId"
						element={<ChecklistPage />}
					/>

					<Route
						path="powerBi/audit/:auditId/read/:draftId/serviceAddress/:serviceAddress"
						element={<AuditPage />}
					/>

					<Route
						path="shift/:shiftId/powerBi/patrol/:patrolId/read/:draftId"
						element={<PatrolDetailsPage status="completed" />}
					/>

					<Route
						path="shift/:shiftId/powerBi/patrol/:patrolId/in-progress/:draftId"
						element={<PatrolDetailsPage status="in-progress" />}
					/>

					<Route
						path="shift/:shiftId/powerBi/journal/:journalId/:journalLogId/read"
						element={<JournalPage read={true} />}
					/>

					<Route
						path="powerBi/journal/:journalId/:journalLogId/customer/:customer/serviceAddress/:serviceAddress/read"
						element={<JournalPage read={true} />}
					/>

					<Route
						path="shift/:shiftId/powerBi/incident/:incidentId/read/:draftId"
						element={<IncidentPage />}
					/>

					<Route
						path="powerBi/incident/:incidentId/read/:draftId"
						element={<ResponsabileIncidentPage />}
					/>

					<Route
						path="shift/:shiftId/powerBi/keys/:keyManagementId/:keyRegistryManagementId/answerId/:answerId/bloccata"
						element={<KeyBloccataPage read={true}/>}
					/>
					<Route
						path="powerBi/keys/customer/:customer/keyManagementId/:keyManagementId/serviceAddress/:serviceAddress/bloccata/keyRegistryId/:keyRegistryId/keyRegistryManagementId/:keyRegistryManagementId/answerId/:answerId"
						element={<KeyBloccataResponsabilePage read={true}/>}
					/>
					<Route
						path="shift/:shiftId/powerBi/keys/:keyManagementId/:keyRegistryManagementId/answerId/:answerId/delivered"
						element={<KeyDeliveredPage />}
					/>

					<Route
						path="powerBi/keys/customer/:customer/keyManagementId/:keyManagementId/serviceAddress/:serviceAddress/delivered/keyRegistryId/:keyRegistryId/keyRegistryManagementId/:keyRegistryManagementId/answerId/:answerId"
						element={<KeyDeliveredResponsabilePage />}
					/>

					<Route
						path="shift/:shiftId/powerBi/controlloAccessi/:guestConfigurationId/:id/dettaglio"
						element={<ControlloAccessiDettaglioPage read={true}/>}
					/>
					<Route
						path="powerBi/controlloAccessi/:guestConfigurationId/customer/:customer/serviceAddress/:serviceAddress/:id/dettaglio"
						element={<ControlloAccessiDettaglioPage read={true}/>}
					/>

					<Route 
						path="shift/:shiftId/powerBi/controlloAccessi/:guestConfigurationId/:id/:guestId/dettaglioSegnalazione/guestCardCheckId/:guestCardCheckId" 
						element={<ControlloAccessiDettaglioSegnalazionePage read={true}/>} 
					/>
					
					<Route 
						path="powerBi/controlloAccessi/:guestConfigurationId/customer/:customer/serviceAddress/:serviceAddress/:id/:guestId/dettaglioSegnalazione/guestCardCheckId/:guestCardCheckId" 
						element={<ControlloAccessiDettaglioSegnalazionePage read={true}/>} 
					/>

					<Route
						path="dashboard/responsabile/customer/:customer/serviceAddress/:serviceAddress"
						element={<ResponsabileDashboardPage />}
					/>


					{/* Controllo accessi */}
					<Route path="shift/:shiftId/controlloAccessi/:guestConfigurationId" element={<ControlloAccessiPage />} />

					<Route path="controlloAccessi/:guestConfigurationId/customer/:customer/serviceAddress/:serviceAddress" element={<ControlloAccessiPage />} />
				
					<Route path="shift/:shiftId/controlloAccessi/:guestConfigurationId/:guestId/:id/storico" element={<StoricoSegnalazioniPage />} />
					
					<Route path="controlloAccessi/:guestConfigurationId/customer/:customer/serviceAddress/:serviceAddress/:guestId/:id/storico" element={<StoricoSegnalazioniPage />} />
					

					<Route path="shift/:shiftId/controlloAccessi/:guestConfigurationId/:id/dettaglio" element={<ControlloAccessiDettaglioPage />} />
					
					<Route path="controlloAccessi/:guestConfigurationId/customer/:customer/serviceAddress/:serviceAddress/:id/dettaglio" element={<ControlloAccessiDettaglioPage />} />

					<Route path="shift/:shiftId/controlloAccessi/:guestConfigurationId/:id/:guestId/dettaglioSegnalazione" element={<ControlloAccessiDettaglioSegnalazionePage />} />
					
					<Route path="controlloAccessi/:guestConfigurationId/customer/:customer/serviceAddress/:serviceAddress/:id/:guestId/dettaglioSegnalazione" element={<ControlloAccessiDettaglioSegnalazionePage />} />

					<Route path="shift/:shiftId/controlloAccessi/:guestConfigurationId/:id/:guestId/dettaglioSegnalazione/guestCardCheckId/:guestCardCheckId" element={<ControlloAccessiDettaglioSegnalazionePage read={true}/>} />
					
					<Route path="controlloAccessi/:guestConfigurationId/customer/:customer/serviceAddress/:serviceAddress/:id/:guestId/dettaglioSegnalazione/guestCardCheckId/:guestCardCheckId" element={<ControlloAccessiDettaglioSegnalazionePage read={true}/>} />

					<Route
						path="checklist/list"
						element={<CustomerModulePage />}
					/>

					<Route
						path="checklist/:auditId"
						element={<AuditItemsPage />}
					/>

					<Route
						path="checklist/:auditId/new"
						element={<AuditPage />}
					/>

					<Route
						path="checklist/:auditId/draft/:draftId"
						element={<AuditPage />}
					/>

					<Route
						path="checklist/:auditId/read/:draftId"
						element={<AuditPage />}
					/>


				</Route>

				{/* //--- MainLayout end  */}
				<Route path="*" element={<Navigate to="/errors/404" replace />} />
			</Routes>
		</>
	);
};

export default Layout;
