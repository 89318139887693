import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

const TrashCanIcon = (props: any) => (
  <FontAwesomeIcon
    icon={faTrashCan}
    width={24}
    height={24}
    size={props.size ? props.size : "lg"}
    {...props}
  />
);

export default TrashCanIcon;
