import { labels } from "features/locale/it-it";
import {
  ChecklistFormStateType,
  QuestionType,
} from "features/storeManagement/services/models/ChecklistResponse";

type props = {
  formData: ChecklistFormStateType;
  setFormData: Function;
  validated: boolean;
  answerArr: string[];
  subtitle: string;
  title: string;
  onClick?: () => void;
  onSelect?: () => void;
  showTimeSelect?: boolean;
  showIcon?: boolean;
  enabled?: boolean;
};

export const selectPropsTimbrature = ({
  formData,
  setFormData,
  validated,
  answerArr,
  subtitle,
  title,
  onClick,
  onSelect,
  enabled = true,
}: props) => {
  return {
    id: 1,
    title: title,
    subtitle: subtitle,
    enabled,
    type: "select" as QuestionType,
    answers: answerArr,
    isMandatory: false,
    answerDetail: null,
    setFormData,
    formData,
    sectionAndQuestionId: labels.keySelectKey,
    isExecutable: true,
    validated: validated,
    fullSize: true,
    onClick,
  };
};

export const datePickerPropsTimbrature = ({
  formData,
  setFormData,
  validated,
  answerArr,
  subtitle,
  onClick,
  showTimeSelect,
  showIcon,
  enabled = true,
}: props) => {
  return {
    id: 1,
    title: "",
    subtitle: subtitle,
    enabled,
    type: "datepicker" as QuestionType,
    answers: answerArr,
    isMandatory: false,
    answerDetail: null,
    setFormData,
    formData,
    sectionAndQuestionId: labels.keySelectKey,
    isExecutable: true,
    validated: validated,
    fullSize: true,
    onClick,
    showTimeSelect,
    showIcon,
  };
};

export const textAreaPropsCheckInNote = ({
  formData,
  setFormData,
  validated,
  answerArr,
  subtitle,
  title,
  enabled = true,
}: props) => {
  return {
    id: 1,
    title,
    subtitle,
    enabled,
    type: "textarea" as QuestionType,
    answers: answerArr,
    isMandatory: false,
    answerDetail: null,
    setFormData,
    formData,
    sectionAndQuestionId: labels.operatoreNote,
    isExecutable: true,
    validated,
  };
};

export const textAreaPropsCheckOutNote = ({
  formData,
  setFormData,
  validated,
  answerArr,
  subtitle,
  title,
  enabled = true,
}: props) => {
  return {
    id: 2,
    title,
    subtitle,
    enabled,
    type: "textarea" as QuestionType,
    answers: answerArr,
    isMandatory: false,
    answerDetail: null,
    setFormData,
    formData,
    sectionAndQuestionId: labels.operatoreNote,
    isExecutable: true,
    validated,
  };
};

export const dateformat = (date: string) => {
  return (
    date.split("-")[1] + "-" + date.split("-")[0] + "-" + date.split("-")[2]
  );
};
