import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const PlusIcon = (props: any) => (
  <FontAwesomeIcon
    icon={faPlusCircle}
    width={24}
    height={24}
    size={props.size ? props.size : "lg"}
    {...props}
  />
);

export default PlusIcon;