/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { v4 as uuid } from 'uuid';

import { authenticationApi, useAttemptLoginMutation, useAttemptLogoutMutation, useSessionRefreshMutation } from "features/storeManagement/services/loginService"
//import { modulesServiceApi, useGetModulesMutation } from "features/storeManagement/services/modulesService"
import { setIsLoggedIn, setRememberMe, setUserInfo } from "features/storeManagement/slices/loggedInSlice"
import { privacyPolicyApi, useAcceptPrivacyPolicyMutation } from "features/storeManagement/services/privacyPolicyService"
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys"
import { RootState } from "features/storeManagement/store"
import { AuthenticationResponse } from "features/storeManagement/services/models/AuthenticationResponse"
import { shiftsApi } from "features/storeManagement/services/shiftsService"
import { checklistApi } from "features/storeManagement/services/checklistService"
import { customerService } from "features/storeManagement/services/customerService"
import { reportApi } from "features/storeManagement/services/reportService";
import { guestApi } from "features/storeManagement/services/guestService";
import { documentsApi } from "features/storeManagement/services/documentsService";
import { parseJwt } from "features/utils/tokenParser";
import { monitorsService } from "features/storeManagement/services/monitorsService";

export const useAuthenticationApp = () => {
	const token = localStorage.token || sessionStorage.token
	const policyAcceptedStatus = localStorage.policyAccepted === 'true' || sessionStorage.policyAccepted === 'true'
	const [checkedLocalStorageToken, setCheckedLocalStorageToken] = useState<boolean>(false)
	const [hasAcceptedPrivacyPolicy, setHasAcceptedPrivacyPolicy] = useState<boolean>(policyAcceptedStatus)
	const [hasAttemptedLoginWithSuccess, setHasAttemptedLoginWithSuccess] = useState<boolean>(false)
	const [lastSessionToken, setLastSessionToken] = useState<string>('')
	const isLoggedIn = useSelector((store: RootState) => store.loggedInSlice.isLoggedIn)
	const rememberMe = useSelector((store: RootState) => store.loggedInSlice.rememberMe)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	
	const [
		sessionRefresh,
		{
			data: sessionRefreshData,
			isLoading: sessionRefreshIsLoading,
			error: sessionRefreshError,
		},
	] = useSessionRefreshMutation({
		fixedCacheKey: fixedCacheKeys.sessionRefreshData,
	});

	useEffect(() => {
		if(isLoggedIn) {
			let timeout = 0;
			const token = sessionStorage.token || localStorage.token;
			const nowTimestamp = Math.round(Date.now() / 1000);

			if(token) {
				const tokenElements : any = parseJwt(token)
				timeout = tokenElements.exp - nowTimestamp - 10;
			}

			timeout > 0 && setTimeout(() => {
				sessionRefresh()
			}, timeout*1000)
		}
	}, [isLoggedIn, lastSessionToken]);
	

	useEffect(() => {
		if (sessionRefreshData) {
			// store the new token
			if (localStorage.refreshToken) localStorage.setItem('refreshToken', sessionRefreshData.refreshToken)
			if (localStorage.token) localStorage.setItem('token', sessionRefreshData.token)
			if (sessionStorage.refreshToken) sessionStorage.setItem('refreshToken', sessionRefreshData.refreshToken)
			if (sessionStorage.token) sessionStorage.setItem('token', sessionRefreshData.token)
			
			setLastSessionToken(localStorage.token || sessionStorage.token)
		}
	}, [sessionRefreshData])


	const [logoutCall, {
		data: logoutData,
		isLoading: logoutIsLoading,
		error: logoutError
	}] = useAttemptLogoutMutation({ fixedCacheKey: fixedCacheKeys.logout })

	// const [getModules, {
	// 	data: modulesData,
	// 	isLoading: moduleIsLoading,
	// 	error: moduleError
	// }] = useGetModulesMutation({ fixedCacheKey: fixedCacheKeys.getModules })

	const [getAuthenticated, {
		data: authenticationData,
		isLoading: authenticationIsLoading,
		error: authenticationError
	}] = useAttemptLoginMutation({ fixedCacheKey: fixedCacheKeys.login })

	const [acceptPrivacyPolicy, {
		data: privacyAcceptData,
		isLoading: privacyIsLoading,
		error: privacyError
	}] = useAcceptPrivacyPolicyMutation({ fixedCacheKey: fixedCacheKeys.privacyPolicyAccepted })

	const getUserInfo = (authenticationData: AuthenticationResponse) => {
		return window.btoa(authenticationData.firstName + ':' + authenticationData.lastName);
	}

	// 1. Login attempted with username and password
	// It is triggered from useLoginForm.tsx
	// Also, wrong credentials and the message are managed there.

	// 2. Check if the Privacy Policy has been accepted
	useEffect(() => {
		if (authenticationData && authenticationData.policyAccepted) {
			setHasAcceptedPrivacyPolicy(true)
		}

		if (privacyAcceptData && privacyAcceptData.policyAccepted) {
			setHasAcceptedPrivacyPolicy(true)
			if(localStorage.getItem('token')){
				localStorage.setItem('policyAccepted', privacyAcceptData.policyAccepted.toString())
			}else if(sessionStorage.getItem('token')){
				sessionStorage.setItem('policyAccepted', privacyAcceptData.policyAccepted.toString())
			}
		}

		if (authenticationData) {
			setHasAttemptedLoginWithSuccess(true)
		}
	}, [authenticationData, privacyAcceptData])

	// IF PRIVACY POLICY IS ACCEPTED

	// 3. Get the modules if Login -> Accept Privacy is done,
	//    Or If localStorage/sessionStorage has tokens already.
	// useEffect(() => {
	// 	if (!!authenticationData && hasAcceptedPrivacyPolicy) {
	// 		getModules(authenticationData.token)
	// 	}
	// }, [authenticationData, hasAcceptedPrivacyPolicy])

	useEffect(() => {
		if (token) {
			// getModules(token)
			dispatch(setIsLoggedIn(true))
		}
	}, [])

	// 3/1. If the modules were gotten with already saved tokens, setLogin true
	// useEffect(() => {
	// 	if (modulesData) {
	// 		dispatch(setIsLoggedIn(true))
	// 	}
	// }, [modulesData])
	useEffect(() => {
		if (authenticationData) {
			dispatch(setIsLoggedIn(true))
		}
	}, [authenticationData])


	// 4. Save the tokens, if not already saved
	useEffect(() => {
		if (authenticationData) {
			dispatch(setIsLoggedIn(true))
			setCheckedLocalStorageToken(true)
			// Add tokens to localStorage/sessionStorage if have not already
			if (!localStorage.token && !sessionStorage.token && authenticationData) {
				if (rememberMe) {
					localStorage.setItem('token', authenticationData.token)
					localStorage.setItem('refreshToken', authenticationData.refreshToken)
					localStorage.setItem('userInfo', getUserInfo(authenticationData))
					localStorage.setItem('cid', authenticationData.cid[0])
					localStorage.setItem('primaryGroup', authenticationData.groups[0])
					localStorage.setItem('policyAccepted', authenticationData.policyAccepted.toString())
					localStorage.setItem('companies', JSON.stringify(authenticationData.companies))
				} else {
					sessionStorage.setItem('token', authenticationData.token)
					sessionStorage.setItem('refreshToken', authenticationData.refreshToken)
					sessionStorage.setItem('userInfo', getUserInfo(authenticationData))
					sessionStorage.setItem('cid', authenticationData.cid[0])
					sessionStorage.setItem('primaryGroup', authenticationData.groups[0])
					sessionStorage.setItem('policyAccepted', authenticationData.policyAccepted.toString())
					sessionStorage.setItem('companies', JSON.stringify(authenticationData.companies))

				}
			}
		}

		if (sessionStorage.userInfo) {
			dispatch(setUserInfo(sessionStorage.userInfo));
		}

		if (localStorage.userInfo) {
			dispatch(setUserInfo(localStorage.userInfo));
		}

		if (!authenticationIsLoading) setCheckedLocalStorageToken(true)
	}, [authenticationData])

	// IF PRIVACY POLICY IS NOT ACCEPTED
	// PrivacyPage is redered, and within it, the text is fetched


	// Successful logout

	useEffect(() => {
		if (logoutData) {
			dispatch(setIsLoggedIn(false))
			setHasAcceptedPrivacyPolicy(false)
			setHasAttemptedLoginWithSuccess(false)
		}
	}, [logoutData])

	// Empty storage on logout
	useEffect(() => {
		if (isLoggedIn === false) {
			localStorage.clear();
			sessionStorage.clear()

			if (process.env.NODE_ENV !== 'development') {
				navigate('/')
			}

			dispatch(authenticationApi.util.resetApiState())
			dispatch(shiftsApi.util.resetApiState())
			dispatch(checklistApi.util.resetApiState())
			//dispatch(modulesServiceApi.util.resetApiState())
			dispatch(privacyPolicyApi.util.resetApiState())
			dispatch(customerService.util.resetApiState())
			dispatch(reportApi.util.resetApiState())
			dispatch(guestApi.util.resetApiState())
			dispatch(documentsApi.util.resetApiState())
			dispatch(monitorsService.util.resetApiState())
		}
	}, [isLoggedIn])

	const authenticatedNotPolicyAccepted = !hasAcceptedPrivacyPolicy
	const isLoading = authenticationIsLoading || /*moduleIsLoading || */logoutIsLoading || !checkedLocalStorageToken || privacyIsLoading

	return {
		isLoggedIn: isLoggedIn && !logoutData,
		isLoading,
		authenticatedNotPolicyAccepted
	}
}