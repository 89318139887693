import React, { SetStateAction, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { format, isValid, parse } from "date-fns";
import { it } from "date-fns/locale";
import "./style.scss";
import { labels } from "features/locale/it-it";
import { ChecklistFormStateType } from "features/storeManagement/services/models/ChecklistResponse";
import Datepicker from "components/content/FormComponents/Datepicker";
import {
  dateformat,
  datePickerPropsTimbrature,
  selectPropsTimbrature,
  textAreaPropsCheckInNote,
  textAreaPropsCheckOutNote,
} from "./Utils/utilsTimbratureFiltri";
import {
  Fabbisogno,
  MonitorsCausals,
  MonitorsEditOrAddRequestBody,
  OperatorStampings,
} from "features/storeManagement/services/models/MonitorsResponse";
import Select from "components/content/FormComponents/Select";
import {
  useGetMonitorsCausalsMutation,
  useGetMonitorsOperatorsStampingsMutation,
  usePostMonitorsAddMutation,
  usePutMonitorsEditMutation,
} from "features/storeManagement/services/monitorsService";
import { fixedCacheKeys } from "features/storeManagement/services/constants/fixedCacheKeys";
import { Spinner } from "react-bootstrap";
import TextAreaModalMonitor from "components/content/FormComponents/TextAreaModalMonitor";

interface modalProps {
  showModal: boolean;
  setShowModal: Function;
  onHide: boolean;
  dataModal: Fabbisogno | null;
  isEditEnabled: boolean;
  operatorIndex: number;
  setHasUpdatedTimbrature: React.Dispatch<SetStateAction<boolean>>;
}

const TimbratureModal = ({
  showModal,
  setShowModal,
  onHide,
  dataModal,
  isEditEnabled,
  operatorIndex,
  setHasUpdatedTimbrature,
}: modalProps) => {
  const [
    getMonitorsCausals,
    {
      data: monitorsCausalsResponse,
      isLoading: monitorsCausalsIsLoading,
      error: monitorsCausalsError,
    },
  ] = useGetMonitorsCausalsMutation({
    fixedCacheKey: fixedCacheKeys.monitorsCausals,
  });

  const [
    getMonitorsOperatorsStampings,
    {
      data: monitorsOperatorsStampingsResponse,
      isLoading: monitorsOperatorsStampingsIsLoading,
      error: monitorsOperatorsStampingsError,
    },
  ] = useGetMonitorsOperatorsStampingsMutation({
    fixedCacheKey: fixedCacheKeys.monitorsOperatorsStampingsResponse,
  });

  const [
    putMonitorsEdit,
    {
      data: putMonitorsEditDataResponse,
      isLoading: putMonitorsEditIsLoading,
      error: putMonitorsEditError,
      isSuccess: putMonitorsEditIsSuccess,
      isError: putMonitorsEditIsError,
    },
  ] = usePutMonitorsEditMutation({
    fixedCacheKey: fixedCacheKeys.putMonitorsEditDataResponse,
  });

  const [
    postMonitorsAdd,
    {
      data: postMonitorsAddDataResponse,
      isLoading: postMonitorsAddIsLoading,
      error: postMonitorsAddError,
      isSuccess: postMonitorsAddIsSuccess,
      isError: postMonitorsAddIsError,
    },
  ] = usePostMonitorsAddMutation({
    fixedCacheKey: fixedCacheKeys.postMonitorsAddDataResponse,
  });

  const [optionsCausals, setOptionsCausals] = useState<string[]>([]);
  const [optionsOperators, setOptionsOperators] = useState<string[]>([]);
  const [selectedOperatorCid, setSelectedOperatorCid] = useState("");
  const [checkInCausalId, setCheckInCausalId] = useState<number>(0);
  const [checkOutCausalId, setCheckOutCausalId] = useState<number>(0);

  const [formDataCreatedDate, setFormDataCreatedDate] =
    useState<ChecklistFormStateType>({});
  const [formDataLastUpdated, setFormDataLastUpdated] =
    useState<ChecklistFormStateType>({});
  const [formManualCheckInDate, setFormManualCheckInDate] =
    useState<ChecklistFormStateType>({});
  const [formManualCheckOutDate, setFormManualCheckOutDate] =
    useState<ChecklistFormStateType>({});
  const [formDataTextAreaCheckInNote, setFormDataTextAreaCheckInNote] =
    useState<ChecklistFormStateType>({});
  const [formDataTextAreaCheckOutNote, setFormDataTextAreaCheckOutNote] =
    useState<ChecklistFormStateType>({});
  const [formCheckInCausal, setFormCheckInCausal] =
    useState<ChecklistFormStateType>({});
  const [formCheckOutCausal, setFormCheckOutCausal] =
    useState<ChecklistFormStateType>({});
  const [formOperatorsStampings, setFormOperatorsStampings] =
    useState<ChecklistFormStateType>({});

  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isCheckInValid, setIsCheckInValid] = useState<boolean>(true);
  const [isCheckOutValid, setIsCheckOutValid] = useState<boolean>(true);

  useEffect(() => {
    if (putMonitorsEditIsSuccess) {
      setHasUpdatedTimbrature(true);
    } else if (putMonitorsEditIsError) {
      setHasUpdatedTimbrature(false);
    }
    setShowModal(false);
  }, [putMonitorsEditIsSuccess, putMonitorsEditIsError]);

  useEffect(() => {
    if (postMonitorsAddIsSuccess) {
      setHasUpdatedTimbrature(true);
    } else if (postMonitorsAddIsError) {
      setHasUpdatedTimbrature(false);
    }
    setShowModal(false);
  }, [postMonitorsAddIsSuccess, postMonitorsAddIsError]);

  const validateForm = () => {
    const name = formOperatorsStampings?.["keyPlaceSelect "]?.[0] || "";

    const manualCheckIn = formManualCheckInDate?.["keyPlaceSelect "]?.[0];
    const checkInCausale = formCheckInCausal?.["keyPlaceSelect "]?.[0];
    const noteCheckIn =
      formDataTextAreaCheckInNote?.[labels.operatoreNote + " "]?.[0] || "";

    const manualCheckOut = formManualCheckOutDate?.["keyPlaceSelect "]?.[0];
    const checkOutCausale = formCheckOutCausal?.["keyPlaceSelect "]?.[0];
    const noteCheckOut =
      formDataTextAreaCheckOutNote?.[labels.operatoreNote + " "]?.[0] || "";

    let isValid = false;

    if (!name) {
      isValid = false;
    } else {
      if (manualCheckIn || manualCheckIn === undefined) {
        if (checkInCausale && noteCheckIn) {
          isValid = true;
          setIsCheckInValid(true);
        } else if (!checkInCausale || !noteCheckIn) {
          isValid = false;
          setIsCheckInValid(false);
        }
      } else if (checkInCausale || noteCheckIn) {
        setFormCheckInCausal({
          ["keyPlaceSelect "]: [""],
        });
        setCheckInCausalId(0);
        setFormDataTextAreaCheckInNote({
          [labels.operatoreNote + " "]: [""],
        });
      }

      if (manualCheckOut) {
        if (checkOutCausale && noteCheckOut) {
          if (isEditEnabled) {
            isValid = true;
          }

          setIsCheckOutValid(true);
        } else if (!checkOutCausale || !noteCheckOut) {
          isValid = false;
          setIsCheckOutValid(false);
        }
      } else if (checkOutCausale || noteCheckOut) {
        setFormCheckOutCausal({
          ["keyPlaceSelect "]: [""],
        });
        setCheckOutCausalId(0);
        setFormDataTextAreaCheckOutNote({
          [labels.operatoreNote + " "]: [""],
        });
      }
    }

    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [
    formOperatorsStampings,
    formDataTextAreaCheckInNote,
    formCheckInCausal,
    formDataTextAreaCheckOutNote,
    formCheckOutCausal,
    formManualCheckInDate,
    formManualCheckOutDate,
  ]);

  useEffect(() => {
    if (!showModal) {
      setIsFormValid(false);
      setIsCheckInValid(true);
      setIsCheckOutValid(true);
    }
  }, [showModal]);

  useEffect(() => {
    if (isEditEnabled && dataModal && operatorIndex >= 0) {
      const operator = dataModal.operators[operatorIndex];
      setFormOperatorsStampings({
        ["keyPlaceSelect "]: [operator?.name || ""],
      });

      // const operatorCid = monitorsOperatorsStampingsResponse?.filter(
      //   (el) => el.name === operator?.name
      // );
      // setSelectedOperatorCid(operatorCid[0]?.operatorCid);

      setFormDataCreatedDate({
        ["keyPlaceSelect "]: [operator?.checkInDate || ""],
      });

      setFormDataLastUpdated({
        ["keyPlaceSelect "]: [operator?.checkOutDate || ""],
      });

      setFormCheckInCausal({
        ["keyPlaceSelect "]: [operator?.checkInCausal || ""],
      });

      setFormCheckOutCausal({
        ["keyPlaceSelect "]: [operator?.checkOutCausal || ""],
      });

      setFormDataTextAreaCheckInNote({
        [labels.operatoreNote + " "]: [operator?.checkInNote || ""],
      });

      setFormDataTextAreaCheckOutNote({
        [labels.operatoreNote + " "]: [operator?.checkOutNote || ""],
      });

      setFormManualCheckInDate({
        ["keyPlaceSelect "]: [
          (operator?.manualCheckInDate &&
            dateformat(operator?.manualCheckInDate)) ||
            "",
        ],
      });

      setFormManualCheckOutDate({
        ["keyPlaceSelect "]: [
          (operator?.manualCheckOutDate &&
            dateformat(operator?.manualCheckOutDate)) ||
            "",
        ],
      });
    } else {
      setFormDataCreatedDate({});
      setFormDataLastUpdated({});
      setFormDataTextAreaCheckInNote({});
      setFormDataTextAreaCheckOutNote({});
      setFormCheckInCausal({});
      setFormCheckOutCausal({});
      setFormManualCheckInDate({});
      setFormManualCheckOutDate({});
    }
  }, [isEditEnabled, dataModal, operatorIndex]);

  useEffect(() => {
    if (!monitorsCausalsResponse && showModal) {
      getMonitorsCausals();
    }

    if (
      !monitorsOperatorsStampingsResponse &&
      showModal &&
      !isEditEnabled &&
      dataModal
    ) {
      getMonitorsOperatorsStampings({ id: dataModal?.id });
    }

    const optionsCausalsArr: { id: number; causal: string }[] = [];
    const optionsOperatorsStampings: OperatorStampings[] = [];

    monitorsCausalsResponse?.map((causals: MonitorsCausals) => {
      if (!optionsCausalsArr.includes(causals)) {
        optionsCausalsArr.push(causals);
      }
    });

    if (monitorsCausalsResponse) {
      const selectedCheckInCausalId = monitorsCausalsResponse.filter(
        (el) => el.causal === formCheckInCausal?.["keyPlaceSelect "]?.[0]
      );

      setCheckInCausalId(selectedCheckInCausalId[0]?.id);
    }

    if (monitorsCausalsResponse) {
      const selectedCheckOutCausalId = monitorsCausalsResponse.filter(
        (el) => el.causal === formCheckOutCausal?.["keyPlaceSelect "]?.[0]
      );
      setCheckOutCausalId(selectedCheckOutCausalId[0]?.id);
    }

    monitorsOperatorsStampingsResponse?.map((operators) => {
      if (!optionsOperatorsStampings.includes(operators)) {
        optionsOperatorsStampings.push(operators);
      }
    });

    setOptionsOperators(optionsOperatorsStampings.map((el) => el.name));
    setOptionsCausals(optionsCausalsArr.map((el) => el.causal));
  }, [monitorsCausalsResponse, monitorsOperatorsStampingsResponse, showModal]);

  useEffect(() => {
    if (monitorsCausalsResponse) {
      const selectedCheckInCausalId = monitorsCausalsResponse.filter(
        (el) => el.causal === formCheckInCausal?.["keyPlaceSelect "]?.[0]
      );
      setCheckInCausalId(selectedCheckInCausalId[0]?.id);
    }
  }, [formCheckInCausal]);

  useEffect(() => {
    if (monitorsCausalsResponse) {
      const selectedCheckOutCausalId = monitorsCausalsResponse.filter(
        (el) => el.causal === formCheckOutCausal?.["keyPlaceSelect "]?.[0]
      );
      setCheckOutCausalId(selectedCheckOutCausalId[0]?.id);
    }
  }, [formCheckOutCausal]);

  useEffect(() => {
    if (monitorsOperatorsStampingsResponse) {
      const operatorCid = monitorsOperatorsStampingsResponse.filter(
        (el) => el.name === formOperatorsStampings?.["keyPlaceSelect "]?.[0]
      );
      setSelectedOperatorCid(operatorCid[0]?.operatorCid);
    }
  }, [formOperatorsStampings]);

  const addOrEditOperatorSubmit = () => {
    if (!isFormValid) {
      return;
    }

    //todo: aggiungere i campi
    if (dataModal) {
      const operatorBodyEdit: MonitorsEditOrAddRequestBody = {
        shiftId: dataModal.id,
        operatorCid: selectedOperatorCid,
        checkInCausal: checkInCausalId === undefined ? null : checkInCausalId,
        checkOutCausal:
          checkOutCausalId === undefined ? null : checkOutCausalId,
        checkInNote:
          formDataTextAreaCheckInNote?.[labels.operatoreNote + " "]?.[0] || "",
        checkOutNote:
          formDataTextAreaCheckOutNote?.[labels.operatoreNote + " "]?.[0] || "",
        manualCheckInDate: formManualCheckInDate?.["keyPlaceSelect "]?.[0]
          ? formManualCheckInDate?.["keyPlaceSelect "]?.[0]
          : null,
        manualCheckOutDate: formManualCheckOutDate?.["keyPlaceSelect "]?.[0]
          ? formManualCheckOutDate?.["keyPlaceSelect "]?.[0]
          : null,
      };
      const operatorBodyAdd: MonitorsEditOrAddRequestBody = {
        shiftId: dataModal.id,
        operatorCid: selectedOperatorCid,
        checkInCausal: checkInCausalId === undefined ? null : checkInCausalId,
        checkOutCausal:
          checkOutCausalId === undefined ? null : checkOutCausalId,
        checkInNote:
          formDataTextAreaCheckInNote?.[labels.operatoreNote + " "]?.[0] || "",
        checkOutNote:
          formDataTextAreaCheckOutNote?.[labels.operatoreNote + " "]?.[0] || "",
        manualCheckInDate: formManualCheckInDate?.["keyPlaceSelect "]?.[0]
          ? formManualCheckInDate?.["keyPlaceSelect "]?.[0]
          : null,
        manualCheckOutDate: formManualCheckOutDate?.["keyPlaceSelect "]?.[0]
          ? formManualCheckOutDate?.["keyPlaceSelect "]?.[0]
          : null,
      };

      if (isEditEnabled) {
        putMonitorsEdit({
          externalStampingId: dataModal.operators[0].id,
          body: operatorBodyEdit,
        });
      } else {
        postMonitorsAdd(operatorBodyAdd);
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      size="lg"
    >
      {dataModal && (
        <>
          <Modal.Header className="border-bottom-0" closeButton></Modal.Header>
          <Modal.Body className="d-flex flex-column gap-3 responsive-text">
            <Row className="d-flex">
              <Col className="item_element__single_modal">
                <div className="blue-text">{labels.tableMonitorsContract}</div>
                <div>{dataModal?.contract}</div>
              </Col>
              <Col className="item_element__single_modal">
                <div className="blue-text">{labels.tableMonitorsPosition}</div>
                <div>{dataModal?.position}</div>
              </Col>

              <Col className="item_element__single_modal">
                <div className="blue-text">{labels.tableMonitorsService}</div>
                <div>{dataModal?.service}</div>
              </Col>
              <Col className="item_element__single_modal">
                <div className="blue-text">
                  {labels.tableMonitorsDestination}
                </div>
                <div>{dataModal?.destination}</div>
              </Col>
              <Col className="item_element__single_modal">
                <div className="blue-text">
                  {labels.tableMonitorsRequirement}
                </div>
                <div className="item_text-break">{dataModal?.requirement}</div>
              </Col>
            </Row>

            {/* Operator Select */}
            <Row>
              <Col xs={12} md={8} className="item_element__single_modal">
                {isEditEnabled ? (
                  <div className="d-flex align-items-baseline gap-2">
                    <div className="blue-text">
                      {labels.tableMonitorsOperator}:
                    </div>
                    {dataModal?.operators[0].name}
                  </div>
                ) : (
                  <div className="blue-text item_element__select_operator w-100">
                    <Select
                      customClass="d-lg-flex gap-5"
                      {...selectPropsTimbrature({
                        formData: formOperatorsStampings,
                        setFormData: setFormOperatorsStampings,
                        validated: false,
                        answerArr: optionsOperators,
                        subtitle: "",
                        title: `${labels.tableMonitorsOperator}*:`,
                      })}
                    />
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                md={6}
                className="item_element__single_modal d-flex flex-column"
              >
                <div className="blue-text">
                  {labels.modalMonitorsStartStampingEffective}:
                </div>
                <div className="item_text-break">
                  {isEditEnabled
                    ? dataModal?.operators[operatorIndex]?.checkInDate
                    : `${labels.modalCheckDateMissing}`}
                </div>
              </Col>
              <Col
                xs={12}
                md={6}
                className="item_element__single_modal d-flex flex-column"
              >
                <div className="blue-text">
                  {labels.modalMonitorsEndStampingEffective}:
                </div>
                <div className="item_text-break">
                  {isEditEnabled
                    ? dataModal?.operators[operatorIndex]?.checkOutDate
                    : `${labels.modalCheckDateMissing}`}
                </div>
              </Col>
            </Row>

            {/* Manual Check-in and Check-out */}
            <Row>
              <Col
                xs={12}
                md={6}
                className="item_element__single_modal d-flex flex-column"
              >
                <div className="blue-text">
                  {labels.modalMonitorsStartStampingManual}*:
                </div>
                <div className="datepicker_timbrature_page">
                  <Datepicker
                    {...datePickerPropsTimbrature({
                      formData: formManualCheckInDate,
                      setFormData: setFormManualCheckInDate,
                      validated: false,
                      answerArr: [],
                      subtitle: "",
                      title: "",
                      showTimeSelect: true,
                      showIcon: true,
                    })}
                  />
                </div>
              </Col>
              <Col
                xs={12}
                md={6}
                className="item_element__single_modal d-flex flex-column"
              >
                <div className="blue-text">
                  {labels.modalMonitorsEndStampingManual}*:
                </div>
                <div className="datepicker_timbrature_page">
                  <Datepicker
                    {...datePickerPropsTimbrature({
                      formData: formManualCheckOutDate,
                      setFormData: setFormManualCheckOutDate,
                      validated: false,
                      answerArr: [],
                      subtitle: "",
                      title: "",
                      showTimeSelect: true,
                      showIcon: true,
                      enabled: isEditEnabled
                        ? true
                        : !!formManualCheckInDate?.["keyPlaceSelect "]?.[0],
                    })}
                  />
                </div>
              </Col>
            </Row>

            {/* Causal Fields*/}
            <Row>
              <Col
                xs={12}
                md={6}
                className="blue-text fw-normal item_element__single_modal"
              >
                <Row>
                  <Select
                    customClass={`h-75 ${
                      !isCheckInValid ? "form_modal_feedback" : ""
                    }`}
                    {...selectPropsTimbrature({
                      formData: formCheckInCausal,
                      setFormData: setFormCheckInCausal,
                      validated: true,
                      answerArr: optionsCausals,
                      subtitle: "",
                      title: `${labels.modalCheckInCausal}*:`,
                      enabled:
                        !!formManualCheckInDate?.["keyPlaceSelect "]?.[0],
                    })}
                  />
                </Row>
              </Col>
              <Col
                xs={12}
                md={6}
                className="blue-text fw-normal item_element__single_modal"
              >
                <Row>
                  <Select
                    customClass={`h-75 ${
                      !isCheckOutValid ? "form_modal_feedback" : ""
                    }`}
                    {...selectPropsTimbrature({
                      formData: formCheckOutCausal,
                      setFormData: setFormCheckOutCausal,
                      validated: true,
                      answerArr: optionsCausals,
                      subtitle: "",
                      title: `${labels.modalCheckOutCausal}*:`,
                      enabled:
                        !!formManualCheckOutDate?.["keyPlaceSelect "]?.[0],
                    })}
                  />
                </Row>
              </Col>
            </Row>

            {/* Notes Fields */}
            <Row>
              <Col
                xs={12}
                md={6}
                className={`blue-text fw-normal item_element__single_modal ${
                  !isCheckInValid ? "form_modal_feedback_note" : ""
                }`}
              >
                <TextAreaModalMonitor
                  {...textAreaPropsCheckInNote({
                    formData: formDataTextAreaCheckInNote,
                    setFormData: setFormDataTextAreaCheckInNote,
                    validated: true,
                    answerArr: [],
                    subtitle: "",
                    title: `${labels.modalCheckInNote}*:`,
                    enabled: !!formManualCheckInDate?.["keyPlaceSelect "]?.[0],
                  })}
                />
              </Col>
              <Col
                xs={12}
                md={6}
                className={`blue-text fw-normal item_element__single_modal ${
                  !isCheckOutValid ? "form_modal_feedback_note" : ""
                }`}
              >
                <TextAreaModalMonitor
                  {...textAreaPropsCheckOutNote({
                    formData: formDataTextAreaCheckOutNote,
                    setFormData: setFormDataTextAreaCheckOutNote,
                    validated: true,
                    answerArr: [],
                    subtitle: "",
                    title: `${labels.modalCheckOutNote}*:`,
                    enabled: !!formManualCheckOutDate?.["keyPlaceSelect "]?.[0],
                  })}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="justify-content-between flex-nowrap">
            <p>
              <small>
                {isEditEnabled
                  ? `${labels.modalInfoEditFooter}`
                  : `${labels.modalInfoAddRemoveFooter}`}
              </small>
            </p>
            {postMonitorsAddIsLoading || putMonitorsEditIsLoading ? (
              <Spinner />
            ) : (
              <Button
                className="timbrature_modal_button_submit"
                onClick={() => addOrEditOperatorSubmit()}
                type="submit"
                disabled={!isFormValid}
              >
                {labels.confirm}
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default TimbratureModal;
