import { fetchBaseQuery, FetchArgs, FetchBaseQueryError, BaseQueryFn, BaseQueryApi, FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react"
import { setIsLoggedIn } from "../../slices/loggedInSlice"
import { useDispatch, useSelector } from "react-redux"
import { RootState, store } from "features/storeManagement/store";

const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_DEV_BASE_URL })

export const baseQueryWithReauth: BaseQueryFn<
	string | FetchArgs,
	unknown,
	FetchBaseQueryError> = async (args, api, extraOptions) => {
		let result : any = await baseQuery(args, api, extraOptions)

		// try to get a new token
		if (result.error && result.error.status === 401) {
			result = await manageRefreshSessionAndRecall(args, api, extraOptions, result)
		}

		return result
	}

const manageRefreshSessionAndRecall = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}, result: any) => {
	let refreshResult: any = await refreshToken(args, api, extraOptions);
	
	if (refreshResult.data) {
		result = recallQuery(args, api, extraOptions)
	} else {
		// sign out
		api.dispatch(setIsLoggedIn(false))
	}
	return result;
}

const refreshToken = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
	let refreshTokenCallArgs = JSON.parse(JSON.stringify(args))
	refreshTokenCallArgs.url = '/Auth/RefreshToken'
	refreshTokenCallArgs.body = JSON.stringify({ RefreshToken: localStorage.refreshToken || sessionStorage.refreshToken })
	refreshTokenCallArgs.method = 'POST'

	let refreshResult: any = await baseQuery(refreshTokenCallArgs, api, extraOptions)
	if (refreshResult.data) {
		// store the new token
		if (localStorage.refreshToken) localStorage.setItem('refreshToken', refreshResult.data.refreshToken)
		if (localStorage.token) localStorage.setItem('token', refreshResult.data.token)
		if (sessionStorage.refreshToken) sessionStorage.setItem('refreshToken', refreshResult.data.refreshToken)
		if (sessionStorage.token) sessionStorage.setItem('token', refreshResult.data.token)
	}
	return refreshResult
}


const recallQuery = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
	// Set new Bearer token
	if (typeof args === 'string') {
		args = {
			url: args,
			headers: {
				Authorization: `Bearer ${localStorage.token || sessionStorage.token}`
			}
		}
	} else {
		args.headers = {
			...args.headers,
			Authorization: `Bearer ${localStorage.token || sessionStorage.token}`
		}
	}

	// retry the initial query, with new token
	let result = await baseQuery(args, api, extraOptions)

	return result
}